<section class="register-bg">
    <div class="logo-pos ">
        <img src="assets/img/Logo.png" alt="">
    </div>

    <span><img src="assets/img/box-1.png" class="img-fluid login-speape" alt=""></span>
    <span><img src="assets/img/sheape2.png" class="img-fluid login-speape2" alt=""></span>

    <div class="resister-bgcol">
        <div class="container pos-set">
            <div class="col-md-6 col-sm-12 col-lg-6 reg-form">
                <div class="panel panel-primary">

                    <div class="panel-body">
                        <div class="head-text">
                            <h2> {{translation?.title}} </h2>
                        </div>

                        <div style="margin-top: 35px">
                            <p class="sub-txt">{{translation?.sub_title}}</p>
                        </div>

                        <div class="row">
                            <form [formGroup]="forgetPasswordForm" autocomplete="off" class="kt-form" id="consent-form">
                                <div class="row" style="margin-top: 20px">
                                    <input type="text"
                                           formControlName="email"
                                           maxlength="255"
                                           class="form-control form-input"
                                           placeholder="{{translation?.forgot_password_form.username}}"
                                           autocomplete=" new-password">
                                    <div *ngIf="isControlHasError('subject','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('subject','required')">
                                        Username / Email is required
                                    </span>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <div class="row" style="margin-top: 15px">
                            <div>
                                <button id="submit" type="submit" value="submit"
                                        [disabled]="!isFormValid()"
                                        (click)="onConfirm($event)"
                                        class="btn btn-primary center submit-btn">
                                    {{translation?.forgot_password_btn}}
                                </button>
                            </div>
                        </div>

                        <div style="margin-top: 15px">
                            <p class="login-link">{{translation?.register_link}}
                                <a [routerLink]="['/register']"><span style="margin-left: 10px">Register</span></a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
