<footer class="web-footer">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-3">
                <div class="web-footer-item">
                    <div class="logo">
                        <a [routerLink]="['/home']"><img src="assets/websiteimg/logo.png " alt="" /></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="web-footer-item">
                    <h3>Navigation</h3>
                    <ul>
                        <li><a [routerLink]="['/home']">Home</a></li>
                        <li><a [routerLink]="['/aboutus']">About Us</a></li>
                        <li><a [routerLink]="['/pricing']">Pricing</a></li>
                        <li><a [routerLink]="['/contact']">Contact</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="web-footer-item">
                    <br />
                    <br />
                    <ul>
                        <li><a href="#">Terms</a></li>
                        <li><a href="#">Privacy</a></li>
                        <li><a href="#">GDPR</a></li>
                        <li><a href="#">Cookies</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="web-footer-item">
                    <h3>Contact Us</h3>
                    <ul>
                        <li><span><i class="fa-solid fa-phone"></i></span> +353 83 479 9896</li>
                        <li><span><i class="fa-regular fa-envelope"></i></span> support@inkdstry.com</li>
                        <li><span><i class="fa-solid fa-house"></i></span>Navan, Ireland</li>
                    </ul>
                    <br />
                    <p>
                        <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                        <a href="https://www.facebook.com/profile.php?id=100092216798515"><i
                                class="fa-brands fa-facebook-f"></i></a>
                        <a href="https://www.instagram.com/inkdstry/"><i class="fa-brands fa-instagram"></i></a>
                    </p>
                </div>
            </div>
        </div>
        <div class="web-copyright-text">
            <p>
                © Copyright 2023 <span>Inkdstry </span> All Rights Reserved. Designed &
                Developed
            </p>
            <div class="web-rq-demo-btn">
                <a [routerLink]="['/contact']">Request A Demo</a>
            </div>
        </div>
    </div>
</footer>