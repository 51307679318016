export class ConsentFormDto {

    consentFormID: number;
    artistID: number;
    title: string;
    content: string;
    pdfURL: string;
    consentFormAddInfoList: any;

    constructor(consentFormDto?) {
        consentFormDto = consentFormDto || {};
        this.consentFormID = consentFormDto.consentFormID || '';
        this.artistID = consentFormDto.artistID || '';
        this.title = consentFormDto.title || '';
        this.content = consentFormDto.content || '';
        this.pdfURL = consentFormDto.pdfURL || '';
        this.consentFormAddInfoList = consentFormDto.consentFormAddInfoList || [];
    }
}
