import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
    selector: 'app-view-uploaded-image',
    templateUrl: './view-uploaded-image.component.html',
    styleUrls: ['./view-uploaded-image.component.scss']
})
export class ViewUploadedImageComponent implements OnInit {

    title: string = 'View Image'
    type: string;
    imageSrc: any | undefined;

    constructor(public dialogRef: MatDialogRef<ViewUploadedImageComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.type = this.data.type;
        this.imageSrc = this.data.imageSrc;

        switch (this.type) {
            case 'IMAGE_ONE':
                this.title = 'View Image [1]'
                break
            case 'IMAGE_TWO':
                this.title = 'View Image [2]'
                break
            case 'IMAGE_THREE':
                this.title = 'View Image [3]'
                break
        }
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }
}
