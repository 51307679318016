<section class="web-aboutus-hero">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-md-8 col-lg-8">
                <div class="web-hero-left-text">
                    <h2>Created By Artists
                    </h2>
                    <h2 class="web-hero-left-text--green-text">For Artists</h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- hero-area-end -->

<!-- Culture Dating area start -->
<section class="culture-dating-area">
    <div class="container">
        <div class="cul-dat-title">
            <h3>Our Mission:</h3>
            <h2>Innovation Is Key</h2>
            <p> Our mission is to revolutionize the way tattoo studio owners and artists manage their business operations.
                We strive to provide an all-in-one solution that simplifies the booking process, tracks sessions,
                and streamlines the overall workflow. Our ultimate goal is to empower all
                individuals involved in the tattoo industry to focus on their craft and provide an
                unbeatable experience for their clients.</p>
        </div>
        <div class="dating-item-area">
            <img src="assets/websiteimg/dat-all-img.png" class="img-fluid" alt="">
        </div>

    </div>
</section>
<!-- Culture Dating area end -->

<!-- contact-need to manage start -->
<section class="contact-need-area">
    <div class="container">
        <div class="contact-need-title">
            <h3>Inkdstry The Only Tool You</h3>
            <h2>need to manage your studio 
                like a pro</h2>
        </div>
    </div>
</section>
<!-- contact-need to manage end -->

<!-- approach area start -->
<section class="approach-area">
    <div class="container">
        <div class="cul-dat-title">
            <h3>Our</h3>
            <h2>approach</h2>

            <p> Our vision is to continuously innovate and enhance our software to meet the ever-changing needs of our clients.
                Through our dedication to excellence, we aim to provide the best possible experience for tattoo
                studio owners, artists, and their customers.<br>
            </p>
        </div>

        <div class="row g-1">
            <div class="col-md-3 col-lg-3">
                <div class="approach-left">
                    <span class="mobile-timeline-img"><img src="assets/websiteimg/time-1.png" class="img-fluid" alt=""></span>
                    <span class="time-in-1"><img src="assets/websiteimg/Icon1.png" class="img-fluid" alt=""></span>
                    <h5>Studio Management<br>
                        Made Easy</h5>
                    <p>Boost efficiency and productivity with our intuitive interface and user-friendly system</p>
                </div>
                <div class="approach-left">
                    <span class="mobile-timeline-img"><img src="assets/websiteimg/time-2.png" class="img-fluid" alt=""></span>
                    <span class="time-in-1 time-in-m-2"><img src="assets/websiteimg/icon-2.png" class="img-fluid" alt=""></span>
                    <h5>100% Client <br>
                        Satisfaction</h5>
                    <p>Increase client satisfaction and retention with our advanced appointment scheduling system
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="approach-meddle meddle-m-none text-center">
                    <img src="assets/websiteimg/app-main.png" class="img-fluid" alt="">
                </div>
            </div>
            <div class="col-md-3 col-lg-3">
                <div class="approach-right">
                    <span class="mobile-timeline-img"><img src="assets/websiteimg/time-3.png" class="img-fluid" alt=""></span>
                    <span class="time-in-3"><img src="assets/websiteimg/icon-3.png" class="img-fluid" alt=""></span>
                    <h5>Streamline and Scale <br>
                        Operations</h5>
                    <p>Grow your business by scaling operations, increasing revenue and saying goodbye to bureaucracy</p>
                </div>
                <div class="approach-right">
                    <span class="mobile-timeline-img"><img src="assets/websiteimg/time-4.png" class="img-fluid" alt=""></span>
                    <span class="time-in-3 time-in-m-4"><img src="assets/websiteimg/icon-4.png" class="img-fluid" alt=""></span>
                    <h5>Make Informed <br>
                        Decisions</h5>
                    <p>Streamline management, track sales, and analyze profitability with our powerful analytics tools</p>
                </div>
            </div>
        </div>
    </div>
</section>

