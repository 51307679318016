import {
  Component,
  OnInit,
  HostListener,
  Renderer2,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { filter, Subscription } from "rxjs";
import { Location, PopStateEvent } from "@angular/common";
import { NavigationEnd, NavigationStart, Router } from "@angular/router";
import PerfectScrollbar from "perfect-scrollbar";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  isSideBarOpen: boolean = true;
  @ViewChild("elementRef", { static: true }) elementRef!: ElementRef;
  constructor(
    public location: Location,
    private router: Router,
    private renderer: Renderer2
  ) {}

  ngOnInit() {
    const width = window.innerWidth;
    if (width < 1192.5) {
      this.isSideBarOpen = false;
    } else {
      this.isSideBarOpen = true;
    }

    console.log(this.router);
    const isWindows = navigator.platform.indexOf("Win") > -1;

    if (isWindows) {
      // if we are on windows OS we activate the perfectScrollbar function

      document
        .getElementsByTagName("body")[0]
        .classList.add("perfect-scrollbar-on");
    } else {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("perfect-scrollbar-off");
    }
    const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
    const elemSidebar = <HTMLElement>(
      document.querySelector(".sidebar .sidebar-wrapper")
    );

    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url != this.lastPoppedUrl)
          this.yScrollStack.push(window.scrollY);
      } else if (event instanceof NavigationEnd) {
        if (event.url == this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else window.scrollTo(0, 0);
      }
    });
    this._router = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
    }
  }
  ngAfterViewInit() {
    this.runOnRouteChange();
  }
  isMap(path) {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    titlee = titlee.slice(1);
    if (path == titlee) {
      return false;
    } else {
      return true;
    }
  }
  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemMainPanel = <HTMLElement>document.querySelector(".main-panel");
      const ps = new PerfectScrollbar(elemMainPanel);
      ps.update();
    }
  }
  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
  @HostListener("window:resize")
  onWindowResize() {
    const width = window.innerWidth;
    const element = this.elementRef.nativeElement;
    const mainWrapper = document.querySelector("#main-panel-cs") as HTMLElement;

    if (width < 1192.5) {
      this.isSideBarOpen = false;
    } else {
      this.isSideBarOpen = true;
    }
  }

  handleHideShowSideBar() {
    this.isSideBarOpen = !this.isSideBarOpen;
    // alert("hide show sidebar");
  }
}
