<section class="chat-area">
    <div class="row">
        <ul class="nav nav-tabs chat-nav-tabs" id="myTab" role="tablist">
            <li class="nav-item active">
                <a class="nav-link" id="whatsapp-tab" data-toggle="tab" href="#whatsapp" role="tab"
                    aria-controls="whatsapp" aria-selected="true">WHATSAPP</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="messenger-tab" data-toggle="tab" href="#messenger" role="tab"
                    aria-controls="messenger" aria-selected="false">MESSENGER</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="instagram-tab" data-toggle="tab" href="#instagram" role="tab"
                    aria-controls="instagram" aria-selected="false">INSTAGRAM</a>
            </li>
        </ul>
        <div class="tab-content chat-tab-content" id="myTabContent">
            <div class="tab-pane fade active in" id="whatsapp" role="tabpanel" aria-labelledby="whatsapp-tab">
                <div class="d-flex">
                    <div class="chat-left-area">
                        <div class="profile-info-wrapper mb-5">
                            <div class="d-flex align-items-center">
                                <div class="me-4 p-relative">
                                    <img src="../../../../assets/img/profile-info.png"
                                        class="img-fluid profile-info-img" />
                                    <div class="active-profile-dot">
                                    </div>
                                </div>
                                <div>
                                    <p class="mb-0 profile-info-name">Zendaya Albert</p>
                                </div>
                            </div>

                        </div>
                        <div class="user-list">
                            <div class="mb-5 user-list-search">
                                <input type="search" class="form-control artist-input w-100 search-input"
                                    placeholder="Search or start new chat" />
                            </div>
                            <div>
                                <div
                                    class="d-flex align-items-center justify-content-between user-chat-div user-chat-active">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div class="user-time-unread-msg">
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                        <div class="unread-msg-circle">
                                            3
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-right-area">
                        <div class="chat-header">
                            <div class="d-flex align-items-center">
                                <div class="chat-header-user-wrapper">
                                    <span>RL</span>
                                    <!-- <img src="image path here"  class="chat-header-user-img"/> -->
                                </div>
                                <p class="chat-header-user-name mb-0">Robert Lewis</p>
                            </div>
                        </div>
                        <div class="chat-body">
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                        </div>
                        <div class="chat-footer">
                            <div class="d-flex align-items-center">
                                <span class="chat-footer-icons-btn">
                                    <img src="../../../../assets/img/smile.png" />
                                </span>
                                <span class="chat-footer-icons-btn">
                                    <img src="../../../../assets/img/Plus.png" />
                                </span>
                                <input type="search" class="form-control artist-input w-100 input-type-msg"
                                    placeholder="Type a message" />
                                <span class="chat-footer-icons-btn">
                                    <img src="../../../../assets/img/microphone-svgrepo-com.png" />
                                </span>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="messenger" role="tabpanel" aria-labelledby="messenger-tab">
                <div class="d-flex">
                    <div class="chat-left-area">
                        <div class="profile-info-wrapper mb-5">
                            <div class="d-flex align-items-center justify-content-between pb-4">
                                <div class="">
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/settings.png" />
                                    </span>
                                </div>
                                <div>
                                    <p class="mb-0 profile-info-name">Messenger</p>
                                </div>
                                <div>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/editing.png" />
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="user-list">
                            <div class="mb-5 user-list-search">
                                <input type="search" class="form-control artist-input w-100 search-input"
                                    placeholder="Search or start new chat" />
                            </div>
                            <div>
                                <div
                                    class="d-flex align-items-center justify-content-between user-chat-div user-chat-active">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div class="user-time-unread-msg">
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                        <div class="unread-msg-circle">
                                            3
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="chat-right-area">
                        <div class="chat-header">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="">
                                    <p class="chat-header-user-name mb-0">Robert Lewis</p>
                                    <p class="mb-0 user-des">Active 4mo Ago</p>
                                </div>
                                <div>
                                    <span class="chat-footer-icons-btn messenger-header-icons">
                                        <img src="../../../../assets/img/call-icon.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn messenger-header-icons">
                                        <img src="../../../../assets/img/video-icon.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn ">
                                        <img src="../../../../assets/img/infomation-icon.png" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="chat-body messenger-chat-body">
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                        </div>
                        <div class="chat-footer messenger-footer">
                            <input type="search" class="form-control artist-input w-100 input-type-messenger"
                            placeholder="Type a message" />
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/image-gallery.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/3873590.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/gif.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/3404134.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/microphone-svgrepo-com.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/2529396.png" />
                                    </span>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/686589.png" />
                                    </span>
                                </div>
                                <div class="d-flex align-items-center">
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/126473.png" />
                                    </span>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane fade" id="instagram" role="tabpanel" aria-labelledby="instagram-tab">
                <div class="d-flex">
                    <div class="chat-left-area">
                        <div class="instagram-info-wrapper mb-3">
                            <div class="d-flex align-items-center justify-content-between w-100">
                                <div class="visibility-hidden">
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/settings.png" />
                                    </span>
                                </div>
                                <div>
                                    <p class="mb-0 profile-info-name">Direct</p>
                                </div>
                                <div>
                                    <span class="chat-footer-icons-btn">
                                        <img src="../../../../assets/img/editing.png" />
                                    </span>
                                </div>
                            </div>

                        </div>
                        <div class="insta-tabs">
                            <ul class="nav nav-tabs" id="myTab" role="tablist">
                                <li class="nav-item active">
                                    <a class="nav-link" id="primary-tab" data-toggle="tab" href="#primary" role="tab"
                                        aria-controls="primary" aria-selected="true">PRIMARY</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="general-tab" data-toggle="tab" href="#general" role="tab"
                                        aria-controls="general" aria-selected="false">GENERAL</a>
                                </li>
                            </ul>
                        </div>
                        <div class="user-list">
                            <div class="tab-content" id="instagram-tab-content">
                                <div class="tab-pane fade active in" id="primary" role="tabpanel" aria-labelledby="primary-tab">
                                    <div
                                    class="d-flex align-items-center justify-content-between user-chat-div user-chat-active">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div class="user-time-unread-msg">
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                        <div class="unread-msg-circle">
                                            3
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>

                                    </div>
                                </div>
                                </div>
                                <div class="tab-pane fade active in" id="general" role="tabpanel" aria-labelledby="general-tab">
                                    <div
                                    class="d-flex align-items-center justify-content-between user-chat-div user-chat-active">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div class="user-time-unread-msg">
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                        <div class="unread-msg-circle">
                                            3
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-between user-chat-div">
                                    <div class="d-flex align-items-center">
                                        <div class="me-3">
                                            <img src="../../../../assets//img/chat-user-1.png" class="chat-user-img" />
                                        </div>
                                        <div>
                                            <p class="mb-0 user-name">Sasha Cohen</p>
                                            <p class="mb-0 user-des"><span>That was wonderful. Thanks dear</span></p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="mb-0 user-des">08:05 Pm</p>

                                    </div>
                                </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="chat-right-area">
                        <div class="chat-header">
                            <div class="d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <div class="chat-header-user-wrapper">
                                        <span>RL</span>
                                        <!-- <img src="image path here"  class="chat-header-user-img"/> -->
                                    </div>
                                    <p class="chat-header-user-name mb-0">Robert Lewis</p>
                                </div>
                                <div>

                                    <span class="chat-footer-icons-btn ">
                                        <img src="../../../../assets/img/infomation-icon.png" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="chat-body">
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Hello Robert Lewis</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>How are you doing today?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message received">
                                <p>Hi Zendaya! Nice to hear you again!</p>
                                <span class="msg-time-received">09:15 Am</span>

                            </div>
                            <div class="message received">
                                <p>I’m fine, thank you! And what about you? Is everything ok?</p>
                                <span class="msg-time-received">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>Yep, thanks!</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                            <div class="message sent">
                                <p>May I ask you a favour?</p>
                                <span class="msg-time-sent">09:15 Am</span>
                            </div>
                        </div>
                        <div class="chat-footer ">
                            <input type="search" class="form-control artist-input w-100 input-type-msg"
                            placeholder="Type a message" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
