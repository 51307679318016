import {Injectable} from "@angular/core";
import {map, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {ResponseDto} from "../models/responseDto";
import {CustomerDTO} from "../models/customerDto";
import {commonUtil} from "../utils/commonUtil";

@Injectable({
    providedIn: "root",
})
export class ClientService {

    baseUrl: string = "/customer/";
    artistID: any = commonUtil.getUserSession().artistID;

    constructor(private api: ApiService) {
    }

    public CreateOrUpdate(entity: CustomerDTO): Observable<ResponseDto> {
        if (isNaN(entity.customerID) || entity.customerID == 0) {
            entity.createdBy = this.artistID;
            return this.api.postWithFile<ResponseDto>(
                this.baseUrl + "saveCustomer",
                entity
            );
        } else {
            return this.api.putWithFile<ResponseDto>(
                this.baseUrl + "updateCustomer/" + entity.customerID,
                entity
            );
        }
    }

    public getAll(): Observable<ResponseDto> {
        return this.api.get(this.baseUrl + `allCustomers`);
    }

    public getById(id: any): Observable<any> {
        return this.api.get(this.baseUrl + `findByID/${id}`);
    }

    public delete(id: any): Observable<any> {
        return this.api.delete(this.baseUrl + `delete/${id}`);
    }

    public searchCustomerForEvents(searchRQ: any): Observable<ResponseDto> {
        return this.api.post<any>(
            this.baseUrl + "searchCustomerForEvents",
            searchRQ
        );
    }

    public createCustomer(entity: CustomerDTO): Observable<ResponseDto> {
        entity.createdBy = this.artistID;
        return this.api.postWithFile<ResponseDto>(
            this.baseUrl + "saveCustomer",
            entity
        );
    }
}
