<div class="sidebar-wrapper">
    <div class="logo">
        <a href="#" class="simple-text">
            <div class="logo-img">
                <img src="../../../assets/img/Logo.png" alt=""/>
            </div>

        </a>
    </div>
    <ul class="nav responsive-nav">
        <li  routerLinkActive="active" *ngFor="let menuItem of menuItems" class="{{menuItem.class}}">
            <a class="side-hover"  [routerLink]="[menuItem.path]">
                <i class="icon-tag"><img class="side-icons" [src]="menuItem.icon"/></i>
                <p>{{menuItem.title}}</p>
            </a>
        </li>
    </ul>
</div>
