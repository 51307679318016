import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ResponseDto } from "../models/responseDto";
import { ApiService } from "./api.service";
import { EventDTO } from "./../models/eventDto";

@Injectable({
  providedIn: "root",
})
export class EventService {
  baseUrl: string = "/event/";
  constructor(private api: ApiService) {}

  public getAll(): Observable<ResponseDto> {
    return this.api.get(this.baseUrl + `allEvents`);
  }

  public getById(id: any): Observable<ResponseDto> {
    return this.api.get(this.baseUrl + `findByID/${id}`);
  }

  public CreateOrUpdate(entity: EventDTO): Observable<ResponseDto> {
    if (isNaN(entity.eventID) || entity.eventID == 0)
      return this.api.postWithFile<ResponseDto>(
        this.baseUrl + "saveEvent",
        entity
      );
    else
      return this.api.putWithFile<ResponseDto>(
        this.baseUrl + "updateEvent/" + entity.eventID,
        entity
      );
  }
}
