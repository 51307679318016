import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslationService} from "../../../../../core/services/transalation.service";
import {ToastrService} from "ngx-toastr";
import {SessionReminderService} from "../../../../../core/services/session-reminder.service";
import {Subscription} from "rxjs";
import {SessionReminderDto} from "../../../../../core/models/session-reminder-dto";
import {commonUtil} from "../../../../../core/utils/commonUtil";

@Component({
    selector: 'app-add-edit-session-reminder',
    templateUrl: './add-edit-session-reminder.component.html',
    styleUrls: ['./add-edit-session-reminder.component.scss']
})
export class AddEditSessionReminderComponent implements OnInit, OnDestroy {

    addEditSessionReminder: FormGroup;
    translation: any;
    selectedLanguage: any = "en";

    onSessionReminderChangeSubs = new Subscription();
    onChangeLanguageSubs = new Subscription();
    onGetLanguageSubs = new Subscription();
    onSaveSessionReminderSubs = new Subscription();

    sessionReminderDto: SessionReminderDto = new SessionReminderDto();

    artistID: any;

    constructor(private translationService: TranslationService,
                private sessionReminderService: SessionReminderService,
                private formBuilder: FormBuilder,
                private toasterService: ToastrService,) {
    }

    ngOnInit(): void {
        this.artistID = commonUtil.getUserSession().artistID;

        this.onSessionReminderChangeSubs = this.sessionReminderService.onSessionReminderChange
            .subscribe(consentFormData => {
                this.sessionReminderDto = new SessionReminderDto(consentFormData);
            });

        this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
            this.selectedLanguage = res;
            this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
                this.translation = data.add_edit_consent_form;
            });
        });

        this.initForm();
    }

    initForm() {
        this.addEditSessionReminder = this.formBuilder.group({
            subject: [this.sessionReminderDto.subject, Validators.compose([
                Validators.required
            ])],
            body: [this.sessionReminderDto.body, Validators.compose([
                Validators.required
            ])]
        });
    }

    ngOnDestroy(): void {
        this.onSessionReminderChangeSubs.unsubscribe();
        this.onChangeLanguageSubs.unsubscribe();
        this.onGetLanguageSubs.unsubscribe();
        this.onSaveSessionReminderSubs.unsubscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.addEditSessionReminder.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    isFormValid() {
        return this.addEditSessionReminder.valid;
    }

    onSaveData($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let formData = Object.assign({}, this.addEditSessionReminder.getRawValue());
        formData.artistID = this.artistID;

        this.sessionReminderService.saveSessionReminder(formData).subscribe((result) => {
            if (result) {
                if (result.status == "SUCCESS") {
                    this.toasterService.success("Session Reminder Saved successfully");
                } else if (result.status == "FAILED") {
                    result.appsErrorMessages.forEach((s) => {
                        this.toasterService.error(s.errorMessage);
                    });
                } else {
                    this.toasterService.error("Something went wrong, Please try again");
                }
            } else {
                this.toasterService.error("Something went wrong, Please try again");
            }
        });
    }
}
