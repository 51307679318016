<footer class="footer" style="    background-color: #000;
border-top: 1px solid gray;
height: 65px;">
    <div class="container-fluid" >
        <nav class="pull-left">
            <p  class="txt-footer"> © 2023, <span class="industry-txt">Inkdstry</span> All Rights Reserved.</p>
        </nav>
    </div>
</footer>

