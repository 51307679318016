import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BillingComponent} from "./billing/billing.component";
import {AdminGuard} from "../../core/guards/admin.guard";
import {SubscriptionsComponent} from './subscriptions/subscriptions.component';
import {PaymentSuccessComponent} from './payment-success/payment-success.component';
import {PaymentFailedComponent} from './payment-failed/payment-failed.component';

const routes: Routes = [
    {path: 'billing', component: BillingComponent, canActivate: [AdminGuard]},
    {path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AdminGuard]},
    {path: 'success', component: PaymentSuccessComponent, canActivate: [AdminGuard]},
    {path: 'failed', component: PaymentFailedComponent, canActivate: [AdminGuard]}
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class BillingRoutingModule {
}
