import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BillingComponent} from './billing/billing.component';
import {BillingRoutingModule} from "./billing-routing.module";
import {SubscriptionsComponent} from './subscriptions/subscriptions.component';
import {PaymentSuccessComponent} from './payment-success/payment-success.component';
import {PaymentFailedComponent} from './payment-failed/payment-failed.component';

@NgModule({
    declarations: [
        BillingComponent,
        SubscriptionsComponent,
        PaymentSuccessComponent,
        PaymentFailedComponent
    ],
    imports: [
        CommonModule,
        BillingRoutingModule
    ]
})
export class BillingModule {
}
