import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslationService} from "../../../core/services/transalation.service";
import {UserDTO} from "../../../core/models/userDto";
import {TemplateService} from "../../../core/services/template.service";
import {Subscription} from "rxjs";
import {commonUtil} from "../../../core/utils/commonUtil";
import {TemplateStatusUpdateComponent} from "./template-status-update/template-status-update.component";

@Component({
    selector: 'app-template',
    templateUrl: './template.component.html',
    styleUrls: ['./template.component.scss']
})
export class TemplateComponent implements OnInit, OnDestroy {

    selectedLanguage: any = 'en';
    translation: any = [];

    userDTO: UserDTO = new UserDTO();

    onUserChangeSubs = new Subscription();
    onChangeLanguageSubs = new Subscription();
    onGetLanguageSubs = new Subscription();

    consentFormStatus: string
    sessionReminderStatus: string
    aftercareStatus: string

    artistID: any;

    constructor(public dialog: MatDialog,
                private translationService: TranslationService,
                private templateService: TemplateService) {
    }

    ngOnInit(): void {
        this.artistID = commonUtil.getUserSession().artistID;

        this.onUserChangeSubs = this.templateService.onUserChange
            .subscribe(user => {
                this.userDTO = new UserDTO(user);
                this.consentFormStatus = this.userDTO.consentFormStatus;
                this.sessionReminderStatus = this.userDTO.sessionReminderStatus;
                this.aftercareStatus = this.userDTO.aftercareStatus;
            });

        this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
            this.selectedLanguage = res;
            this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
                this.translation = data.templates.btn_actions;

            });
        });
    }

    ngOnDestroy(): void {
        this.onUserChangeSubs.unsubscribe();
        this.onChangeLanguageSubs.unsubscribe();
        this.onGetLanguageSubs.unsubscribe();
    }

    changeStatus($event: MouseEvent, templateType: string, status: string) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let submitData = {
            'templateType': templateType,
            'status': status,
            'artistID': this.artistID
        }

        const dialogRef = this.dialog.open(TemplateStatusUpdateComponent, {
            width: '30%',
            panelClass: 'custom-dialog-panel',
            data: submitData
        });

        // this.templateService.updateTemplateStatus(submitData)
    }
}
