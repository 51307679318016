import {Component, OnDestroy, OnInit} from "@angular/core";
import { AddArtistComponent } from "../add-artist/add-artist.component";
import { DeleteComponent } from "../../../modal-popups/delete/delete.component";
import { MatDialog } from "@angular/material/dialog";
import { TranslationService } from "../../../core/services/transalation.service";
import { ArtistService } from "app/core/services/artist.service";
import { ToastrService } from "ngx-toastr";
import { UserSessionDto } from "../../../core/models/userSessionDto";
import { ArtistSessionHistoryComponent } from "../artist-session-history/artist-session-history.component";
import {Subscription} from "rxjs";

@Component({
  selector: "app-artist",
  templateUrl: "./artist.component.html",
  styleUrls: ["./artist.component.scss"],
})
export class ArtistComponent implements OnInit, OnDestroy {
  qSH: any;
  itemsPerPageSH = 5;
  currentPageSH = 1;
  public termSH: any;

  public dataList: any[] = [];
  selectedLanguage: any = "en";
  translation: any = [];
  actions: any = [];

  onChangeLanguageSubs = new Subscription();
  onGetLanguageSubs = new Subscription();
  onGetAllArtistSubs = new Subscription();

  constructor(
    public dialog: MatDialog,
    private translationService: TranslationService,
    private _service: ArtistService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
      this.selectedLanguage = res;
      this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
        this.translation = data.artist_listing;
        this.actions = data.actions;
      });
    });

    this.getList();
  }

  getList() {
    this.onGetAllArtistSubs = this._service.getAll().subscribe((result) => {
      if (result.status == "SUCCESS") {
        this.dataList = result.result;
      } else if (result.status == "FAILED") {
        result.appsErrorMessages.forEach((s) => {
          this.toastr.error(s.errorMessage);
        });
      } else {
        this.toastr.error("Someting went wrong during register user");
      }
    });
  }
  openModal() {
    const dialogRef = this.dialog.open(AddArtistComponent, {
      width: "80rem",
    });
    const dialogSubs = dialogRef.afterClosed().subscribe((result) => {
      this.getList();
      console.log(`Dialog result: ${result}`); // Pizza!
      dialogSubs.unsubscribe();
    });
  }

  editModal(item: UserSessionDto) {
    if (item && item.password) {
      item.password = "***";
    }

    const dialogRef = this.dialog.open(AddArtistComponent, {
      width: "80rem",
      data: { ...item },
    });

    const dialogSubs = dialogRef.afterClosed().subscribe((result) => {
      this.getList();
      console.log(`Dialog result: ${result}`); // Pizza!
      dialogSubs.unsubscribe();
    });
  }
  viewModal(item: UserSessionDto) {
    if (item && item.artistID > 0) {
      const dialogRef = this.dialog.open(ArtistSessionHistoryComponent, {
        width: "100%",
        data: { ...item },
      });

      const dialogSubs = dialogRef.afterClosed().subscribe((result) => {
        console.log(`Dialog result: ${result}`); // Pizza!
        dialogSubs.unsubscribe();
      });
    }
  }

  deleteModel(item: UserSessionDto) {
    const dialogRef = this.dialog.open(DeleteComponent, {
      width: "80rem",
    });

    const dialogSubs = dialogRef.afterClosed().subscribe((result) => {
      this.getList();
      console.log(`Dialog result: ${result}`); // Pizza!
      dialogSubs.unsubscribe();
    });
  }

  ngOnDestroy(): void {
    this.onChangeLanguageSubs.unsubscribe();
    this.onGetLanguageSubs.unsubscribe();
    this.onGetAllArtistSubs.unsubscribe();
  }
}
