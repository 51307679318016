import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {ContactUsService} from "../../core/services/contact-us.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {

    contactUsForm: FormGroup;
    onsubmitContactUsSubs = new Subscription();

    constructor(private formBuilder: FormBuilder,
                private toasterService: ToastrService,
                private contactUsService: ContactUsService) {
    }

    ngOnInit(): void {
        this.onsubmitContactUsSubs = this.contactUsService.onsubmitContactUs
            .subscribe(response => {
                if (response) {
                    if (response.status == "SUCCESS") {
                        this.toasterService.success("Successfully submitted..!");
                        this.clearForm();
                    } else if (response.status == "FAILED") {
                        response.appsErrorMessages.forEach((s) => {
                            this.toasterService.error(s.errorMessage);
                        });
                    } else {
                        this.toasterService.error("Something went wrong, Please try again");
                    }
                } else {
                    this.toasterService.error("Something went wrong, Please try again");
                }
            });

        this.initForm();
    }

    initForm() {
        this.contactUsForm = this.formBuilder.group({
            name: ['', Validators.compose([
                Validators.required
            ])],
            email: ['', Validators.compose([
                Validators.required
            ])],
            phone: ['', Validators.compose([
                Validators.required
            ])],
            message: ['']
        });
    }

    clearForm() {
        this.contactUsForm.reset({
            name: '',
            email: '',
            phone: '',
            message: '',
        }, {onlySelf: false, emitEvent: true});
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.contactUsForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    onContactUsSubmit($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        if (!this.isFormValid()) {
            this.toasterService.warning("Please fill the required fields..!");
            return;
        }

        let formData = Object.assign({}, this.contactUsForm.getRawValue());
        this.contactUsService.submitContactUs(formData);
    }

    isFormValid() {
        return this.contactUsForm.valid;
    }

    ngOnDestroy(): void {
        this.onsubmitContactUsSubs.unsubscribe();
    }
}
