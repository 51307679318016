import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {commonUtil} from "../utils/commonUtil";
import {SETTINGS} from "../../main/commons.settings";
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class ScheduleGuard implements CanActivate {

    constructor(private toasterService: ToastrService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!commonUtil.hasSubscribed()) {
            if (commonUtil.isLoggedAdmin()) {
                this.toasterService.warning(SETTINGS.TOAST_MESSAGE.SUBSCRIPTION_VALIDATE_MESSAGE);
            } else {
                this.toasterService.warning(SETTINGS.TOAST_MESSAGE.SUBSCRIPTION_VALIDATE_MESSAGE_USER);
            }

            return false;
        } else {
            return true;
        }
    }
}
