export class CustomerDTO {
    customerID: number;
    stripeID?: any;
    firstName: string;
    lastName: string;
    telNumber: string;
    email: string;
    createDate: Date;
    profilePicURL: string;
    createdBy: number;

    constructor(customerDto?) {
        customerDto = customerDto || {};
        this.customerID = customerDto.customerID || '';
        this.stripeID = customerDto.stripeID || '';
        this.firstName = customerDto.firstName || '';
        this.lastName = customerDto.lastName || '';
        this.telNumber = customerDto.telNumber || '';
        this.email = customerDto.email || '';
        this.createDate = customerDto.createDate || '';
        this.createdBy = customerDto.createdBy || '';
        this.profilePicURL = customerDto.profilePicURL || '';
    }
}
