<div class="container-fluid">

    <div class="row artist-header">

        <div class="col-md-12">
            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <div class="img-styl">

                        <div class="form-group" *ngIf="isSelected">
                            <label class="upload-labal" for="file">
                                <img class="img-profile" style="width: 93px; height: 93px;" src="assets/img/Logo.png"
                                    alt=""></label>
                            <!-- <input id="file" name="photo" type="file" class="form-control uploadFile"
                                (change)="onFileChange($event)" accept="image/*"> -->
                        </div>

                        <!-- <img [src]="imageSrc" *ngIf="imageSrc" class="img-pos"> -->
                    </div>
                    <a class="close-btn" (click)="closeModal()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                    <!-- <img class="edit-profile" src="assets/img/edit-profile.png" alt=""> -->
                </div>
            </div>


        </div>
    </div>

    <form action="" [formGroup]="clientForm" (ngSubmit)="addClient(clientForm)">
        <div class="row">
            <div class="col-md-2">
                <p class="client-text">{{translation?.firstName}}</p>
            </div>
            <div class="col-md-10 mb-4">
                <input type="text" formControlName="firstName" class="form-control artist-input" placeholder="Mary">
                <div *ngIf="clientForm.get('firstName').invalid && submitted">
                    <span class="text-danger" *ngIf="clientForm.get('firstName').errors.required">First Name is
                        required</span>
                </div>
            </div>
        </div>
        <div class="row ">
            <div class="col-md-2">
                <p class="client-text">{{translation?.lastName}}</p>
            </div>
            <div class="col-md-10 mb-4">
                <input type="text" formControlName="lastName" class="form-control artist-input" placeholder="Murphy">
                <div *ngIf="clientForm.get('lastName').invalid && submitted">
                    <span class="text-danger" *ngIf="clientForm.get('lastName').errors.required">Last Name is
                        required</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p class="client-text">{{translation?.phone}}</p>
            </div>
            <div class="col-md-10 mb-4">
                <input type="text" formControlName="telNumber" class="form-control artist-input"
                    placeholder="000-000-000">
                <div *ngIf="clientForm.get('telNumber').invalid && submitted">
                    <span class="text-danger" *ngIf="clientForm.get('telNumber').errors.required">Telephone is
                        required</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-2">
                <p class="client-text">{{translation?.email}}</p>
            </div>
            <div class="col-md-10 mb-4">
                <input type="email" formControlName="email" class="form-control artist-input"
                    placeholder="merrylana@gmail.com">
                <div *ngIf="clientForm.get('email').invalid && submitted">
                    <span class="text-danger" *ngIf="clientForm.get('email').errors.required">Email is
                        required</span>
                    <span class="text-danger" *ngIf="clientForm.get('email').errors.email">Email is not
                        valid</span>
                </div>
            </div>
        </div>
        <div class="row form-margin">
            <div class="col-md-12">
                <button class="btn btn-default artist-btn" type="submit">{{translation?.btn_save}}</button>
            </div>
        </div>
    </form>
</div>