<div class="container-fluid">

    <div class="row additional-info-header ">
        <div class="col-md-12">
            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <h2 style="margin-top: 12px">Add Additional Information</h2>

                    <a class="close-btn" (click)="onCancelClick()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row m-l-r-15">
        <form [formGroup]="addEditAdditionalInfoForm" autocomplete="off" class="kt-form" id="consent-form">
            <div class="row">
                <div class="col-md-12" style="margin-top: 20px">
                    <textarea type="text" formControlName="additionalInfo" class="form-control add-info-input" rows="4"
                        maxlength="255" placeholder="Additional Information" autocomplete=" new-password">
                                </textarea>
                    <div *ngIf="isControlHasError('additionalInfo','required')">
                        <span class="text-danger" *ngIf="isControlHasError('content','required')">
                            Required
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>

    <div class="row" style="margin-top: 15px">
        <div style="text-align: end">
            <button class="btn btn-default add-info-btn" [disabled]="!isValidForm()" (click)="onAdd($event)">
                ADD
            </button>
        </div>
    </div>
</div>