<div class="container-fluid">
    <div class="row view-event-image-header artist-header">
        <div class="col-md-12">
            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <h2 style="margin-top: 12px">{{title}}</h2>

                    <a class="close-btn" (click)="onCancelClick()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>

            </div>
        </div>
    </div>

    <div class="row" style="margin-top: 20px">
        <div class="view-img">
            <img [src]="imageSrc" class="img-pos">
        </div>
    </div>
</div>