import {environment} from "../../environments/environment";

const API_URL = `${environment.baseURL}`;

export class SETTINGS {

	public static IMAGE_BASE_URL = 'https://inkdstry-document.fra1.cdn.digitaloceanspaces.com/media/image-data';
	public static PDF_BASE_URL = 'https://inkdstry-document.fra1.cdn.digitaloceanspaces.com/media/pdf-data/';
	public static UPLOAD_IMAGE_SIZE_MAX_MB = 25;
	public static UPLOAD_IMAGE_ALLOWED_EXTENSIONS = ['jpg', 'png', 'jpeg'];

	public static ENDPOINTS = {
		uploadImage: {
			headerParam: {
				isFileUpload: true
			},
			url: `${API_URL}/storage/uploadImage`,
			type: 'POST'
		},
	}

	public static STORAGE = {
		SELECTED_CUSTOMER_ID: 'ZZxdGgO_'
	}

	public static TOAST_MESSAGE = {
		SUBSCRIPTION_VALIDATE_MESSAGE: 'Please buy a Subscription',
		SUBSCRIPTION_VALIDATE_MESSAGE_USER: 'Please contact admin'
	}
}

// url: '/storage/uploadImage',


