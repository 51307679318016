import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SETTINGS} from "../../../main/commons.settings";

@Component({
    selector: 'app-view-pdf',
    templateUrl: './view-pdf.component.html',
    styleUrls: ['./view-pdf.component.scss']
})
export class ViewPdfComponent implements OnInit {

    title: string = 'View PDF'
    pdfURL;
    pdfSrc;
    isConsentForm = 'N';

    constructor(public dialogRef: MatDialogRef<ViewPdfComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    }

    ngOnInit(): void {
        this.title = this.data.title;
        this.pdfURL = this.data.pdfURL;
        this.isConsentForm = this.data.isConsentForm;

        if (this.isConsentForm === 'Y') {
            this.pdfSrc = this.pdfURL;
        } else {
            this.pdfSrc = SETTINGS.PDF_BASE_URL + this.pdfURL;
        }
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }
}
