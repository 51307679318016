import {Component, OnDestroy, OnInit} from '@angular/core';
import { AccountService } from 'app/core/services/account.service';
import { SubscriptionsService } from 'app/core/services/subscriptions.service';
import { commonUtil } from 'app/core/utils/commonUtil';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
import { UserSessionDto } from 'app/core/models/userSessionDto';
import { Router } from '@angular/router';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss']
})
export class SubscriptionsComponent implements OnInit, OnDestroy {
  artistID: any = commonUtil.getUserSession().artistID;
  stripePromise = loadStripe(environment.stripeKey);
  planType: any = commonUtil.getUserSession().planType;
  user: UserSessionDto = commonUtil.getUserSession();

  onGetSessionDetailSubs = new Subscription();
  onUpgradeSubscriptionSubs = new Subscription();

  constructor(private _service: SubscriptionsService,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnInit(): void {

  }

  getSesionDetails(planId: string) {
    this.onGetSessionDetailSubs = this._service.getSessionDetail(this.artistID, planId).subscribe(async(result) => {
      const stripe = await this.stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: result.result.id,
      });
      // If `redirectToCheckout` fails due to a browser or network
      // error, display the localized error message to your customer
      // using `error.message`.
      if (error) {
        // this.notification.error(error.message);
        this.toastr.error(error.message);
      }
    });
  }

  upgradeSubscription(planId: string) {
    this.onUpgradeSubscriptionSubs = this._service.upgradeSubscription(this.artistID, planId).subscribe(async(result) => {
      this.user.planType = planId;
      this.planType = planId;
      commonUtil.setLoggedInSession(this.user);
      this.toastr.success("Subscription upgrade Successfully!");
      this.router.navigateByUrl('/billing');
    });
  }

  ngOnDestroy(): void {
    this.onGetSessionDetailSubs.unsubscribe();
    this.onUpgradeSubscriptionSubs.unsubscribe();
  }
}
