<div class="container-fluid">
    <div class="row artist-header">
        <div class="col-md-12">

            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <div class="img-styl">
                        <div class="form-group">
                            <label class="upload-labal" for="file" onclick="onFileClick()">
                                <img class="img-profile" style="width: 93px; height: 93px;"
                                    [src]="profilePic ?? imageSrc ?? 'assets/img/Logo.png'" alt="" />
                                <input id="file" name="photo" id="file" type="file" class="form-control uploadFile"
                                    (change)="onFileChange($event)" accept="image/*" />
                                <!-- <img class="img-profile" [src]="profilePic" src="assets/img/client-profile.png" alt="" /> -->
                                <!-- <img class="edit-profile" src="assets/img/Logo.png" alt="" /> -->
                            </label>
                            <!-- <img [src]="profilePic" *ngIf="imageSrc || profilePic" class="img-pos"> -->
                        </div>
                    </div>
                    <a class="close-btn" (click)="closeModal()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>
            </div>




            <!--               <p  class="artist-text"  >Add Artist Detail</p>-->
            <!--               <a   class="close-btn"  (click)="closeModal()"> -->
            <!--                    <img class="close-img" src="assets/img/close.png" alt="">-->
            <!--               </a>-->
        </div>

    </div>
    <form [formGroup]="artistForm" (ngSubmit)="addArtist(artistForm)">
        <div class="row">
            <div class="col-md-6 mb-4">
                <input type="text" formControlName="firstName" class="form-control artist-input"
                    placeholder="{{translation?.first_name}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('firstName').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('firstName').errors.required">First Name is
                        required</span>
                </div>
            </div>
            <div class="col-md-6 mb-4">
                <input type="text" formControlName="lastName" class="form-control artist-input"
                    placeholder="{{translation?.last_name}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('lastName').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('lastName').errors.required">Last Name is
                        required</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 mb-4">
                <input type="text" formControlName="username" class="form-control artist-input"
                    placeholder="{{translation?.user_name}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('username').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('username').errors.required">User Name is
                        required</span>
                </div>
            </div>
            <!-- <div class="col-md-6">
                <input type="text" formControlName="number" class="form-control artist-input"
                    placeholder="{{translation?.number}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('number').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('number').errors.required">Telephone is
                        required</span>
                </div>
            </div> -->
            <div class="col-md-6 mb-4">
                <input type="email" formControlName="email" class="form-control artist-input"
                    placeholder="{{translation?.email}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('email').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('email').errors.required">Email is
                        required</span>
                    <span class="text-danger" *ngIf="artistForm.get('email').errors.email">Email is not
                        valid</span>
                </div>
            </div>
        </div>
        <div class="row">

            <div class="col-md-6 mb-4">
                <input type="password" formControlName="password" class="form-control artist-input"
                    placeholder="{{translation?.password}}" autocomplete=" new-password">
                <div *ngIf="artistForm.get('password').invalid && submitted">
                    <span class="text-danger" *ngIf="artistForm.get('password').errors.required">Password is
                        required</span>
                </div>
            </div>
        </div>

        <div class="row form-margin">
            <div class="col-md-12">
                <button class="btn btn-default artist-btn" type="submit">{{translation?.btn_submit}}</button>
            </div>
        </div>
    </form>

</div>