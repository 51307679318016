import {Routes} from '@angular/router';
import {AuthGuard} from "../../core/guards/auth.guard";
import {AdminGuard} from "../../core/guards/admin.guard";
import {ScheduleGuard} from "../../core/guards/schedule.guard";
import {BillingSubscriptionGuard} from "../../core/guards/billing-subscription.guard";

export const LayoutRoutes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                canActivate: ([BillingSubscriptionGuard]),
                loadChildren: () => import('../dashboard/dashboard.module').then(m => m.DashboardModule)
            },
            {
                path: 'schedule',
                canActivate: ([ScheduleGuard]),
                loadChildren: () => import('../schedule/schedule.module').then(m => m.ScheduleModule)
            },
            {
                path: 'client',
                canActivate: ([BillingSubscriptionGuard]),
                loadChildren: () => import('../client/client.module').then(m => m.ClientModule)
            },
            {
                path: 'artist',
                canActivate: ([BillingSubscriptionGuard]),
                loadChildren: () => import('../artist/artist.module').then(m => m.ArtistModule)
            },
            {
                path: 'template',
                canActivate: ([BillingSubscriptionGuard]),
                loadChildren: () => import('../template/template.module').then(m => m.TemplateModule)
            },
            // {
            //     path: 'chat',
            //     canActivate: ([BillingSubscriptionGuard]),
            //     loadChildren: () => import('../chat/chat.module').then(m => m.ChatModule)
            // },
            {
                path: 'billing',
                canActivate: ([AdminGuard]),
                loadChildren: () => import('../billing/billing.module').then(m => m.BillingModule)
            },
        ]
    }
];
