import {Injectable} from '@angular/core';
import {ApiService} from "./api.service";
import {BehaviorSubject, Observable, Subject, Subscription} from "rxjs";
import {ResponseDto} from "../models/responseDto";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {commonUtil} from "../utils/commonUtil";

@Injectable({
    providedIn: 'root'
})
export class TemplateService {

    baseUrl: string = "/user/";
    baseUrlTemplate: string = "/template/";
    artistID: any = commonUtil.getUserSession().artistID;

    onUserChange: BehaviorSubject<any> = new BehaviorSubject({});
    onTemplateStatusUpdate: Subject<any> = new Subject<any>();

    constructor(private api: ApiService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getUserDTO()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    public updateTemplateStatus(dataRQ: any): Subscription {
        return this.api.post<any>(
            this.baseUrlTemplate + "updateTemplateStatus",
            dataRQ
        ).subscribe((response: any) => {
            this.onTemplateStatusUpdate.next(response);
        })
    }

    getUserDTO(): Promise<any> {
        return new Promise((resolve, reject) => {

            this.api.getByID(this.baseUrl + 'findByID/' + this.artistID).subscribe((response: any) => {
                if (response.status === 'SUCCESS') {
                    this.onUserChange.next(response.result);
                } else {
                    this.onUserChange.next({});
                }
                resolve(response);
            }, reject);
        });
    }
}
