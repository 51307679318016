import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSessionDto } from 'app/core/models/userSessionDto';
import { SubscriptionsService } from 'app/core/services/subscriptions.service';
import { commonUtil } from 'app/core/utils/commonUtil';
import { ToastrService } from 'ngx-toastr';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit, OnDestroy {
  artistID: any = commonUtil.getUserSession().artistID;
  user: UserSessionDto = commonUtil.getUserSession();

  onQueryParamsChangesSubs = new Subscription();
  onGetChargeSessionTokenSubs = new Subscription();

  constructor(private _service: SubscriptionsService,
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router) { }

  ngOnDestroy(): void {
        this.onQueryParamsChangesSubs.unsubscribe();
        this.onGetChargeSessionTokenSubs.unsubscribe();
    }

  ngOnInit(): void {
    this.onQueryParamsChangesSubs = this.route.queryParams
    .subscribe(params => {
      console.log(params); // { orderby: "price" }
      if(params) {
        this.chargeToken(params.session_id, params.plan_id);
      }
    }
  );
  }

  chargeToken(sessionId: string, planId: string) {
    this.onGetChargeSessionTokenSubs = this._service.chargeSessionToken(this.artistID, sessionId, planId).subscribe(async(result) => {
      this.toastr.success(result.result.message);
      this.user.planType = planId;
      commonUtil.setLoggedInSession(this.user);

      this.router.navigateByUrl('/billing');
    });
  }
}
