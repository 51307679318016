<nav class="navbar navbar-default">
    <div class="navbar-container d-flex align-items-center justify-content-between">
        <div class="navbar-header">
            <a class="navbar-brand">{{getTitle()}}</a>
        </div>
        <div class="">
            <ul class="nav navbar-nav navbar-right">
                <li class="dropdown language-pos">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <i class="fa fa-globe" *ngIf="selectedLanguage === 'en'"><span
                                class="language-txt">EN</span></i>
                        <i class="fa fa-globe" *ngIf="selectedLanguage === 'de'"><span
                                class="language-txt">DE</span></i>
                    </a>
                    <ul class="dropdown-menu lang">
                        <li *ngFor="let language of languages" (click)="changeLanguage(language.lang)">
                            <a>{{language.label}}</a>
                        </li>
                    </ul>
                </li>
                <li class="ball-pos">
                    <a href="#" class="bell-icon">
                        <img src="assets/img/bell-icon.png" alt="">
                    </a>
                </li>
                <li class="dropdown">
                    <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                        <img src="assets/img/Profile-nav.png" alt="">
                        Hi, {{username}}</a>
                    <ul class="dropdown-menu profile">
                        <li><a [routerLink]="['/billing']">{{translation[0]}}</a></li>
                        <li><a (click)="logout()">{{translation[1]}}</a></li>

                    </ul>
                </li>

            </ul>
        </div>
    </div>
</nav>