<h1 mat-dialog-title>Client Detail</h1>
<div mat-dialog-content>
  <div class="artists-head">
    <div class="col-md-12 session-table">
      <table id="artist-table" *ngIf="translation && translation?.grid_columns">
        <thead>
          <tr>
            <th></th>
            <th>
              {{ translation?.grid_columns[0]
              }}<img
                class="up-down"
                src="../../../../assets/img/up-dwn.png"
                alt=""
              />
            </th>
            <th>
              {{ translation?.grid_columns[1] }}
              <img
                class="up-down"
                src="../../../../assets/img/up-dwn.png"
                alt=""
              />
            </th>
            <th>
              {{ translation?.grid_columns[2] }}
              <img
                class="up-down"
                src="../../../../assets/img/up-dwn.png"
                alt=""
              />
            </th>
            <th>
              {{ translation?.grid_columns[3]
              }}<img
                class="up-down"
                src="../../../../assets/img/up-dwn.png"
                alt=""
              />
            </th>
            <th>
              {{ translation?.grid_columns[4]
              }}<img
                class="up-down"
                src="../../../../assets/img/up-dwn.png"
                alt=""
              />
            </th>
            <th>{{ translation?.grid_columns[5] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="
              let item of filterEvents
                | paginate : { itemsPerPage: 5, currentPage: qSH }
            "
          >
            <td>
              <span
                ><img
                  class="profile"
                  src="../../../../assets/img/Logo.png"
                  alt=""
              /></span>
            </td>
            <td>
              <span
                >{{ item.userDTO.firstName }} {{ item.userDTO.lastName }}</span
              >
            </td>
            <td>
              <span
                >{{ item.customerDTO.firstName }}
                {{ item.customerDTO.lastName }}
              </span>
            </td>
            <td>
              <span> {{ item.customerDTO.telNumber }}</span>
            </td>
            <td>
              <span class="cell-no">{{ item.startDateStr }} </span>
            </td>
            <td>
              <span>
                {{ timeConvert(item.startTime) }} to
                {{ timeConvert(item.endTime) }}</span
              >
            </td>
            <td>
              <span class="dropdown">
                <a
                  (click)="viewModal(item)"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <img
                    class="eye-icon"
                    src="../../../../assets/img/eye.png"
                    alt=""
                  />
                </a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        class="pagination-pos"
        (pageChange)="qSH = $event"
      ></pagination-controls>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-dialog-close class="btn btn-default add-btn">Close</button>

</div>
