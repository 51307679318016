import {Component, Inject, OnDestroy, OnInit} from "@angular/core";
import { TranslationService } from "../../../core/services/transalation.service";
import { EventDTO } from "app/core/models/eventDto";
import { EventService } from "app/core/services/event.service";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { ArtistService } from "app/core/services/artist.service";
import { CommonService } from "app/core/services/common.service";
import { commonUtil } from "app/core/utils/commonUtil";
import {ViewUploadedImageComponent} from "../../schedule/view-uploaded-image/view-uploaded-image.component";
import {ViewPdfComponent} from "../../schedule/view-pdf/view-pdf.component";
import {Subscription} from "rxjs";
import {SETTINGS} from "../../../main/commons.settings";

@Component({
  selector: "app-appointment-detail",
  templateUrl: "./appointment-detail.component.html",
  styleUrls: ["./appointment-detail.component.scss"],
})
export class AppointmentDetailComponent implements OnInit, OnDestroy {
  isShowPDF: boolean = false;
  event: EventDTO = null;
  selectedLanguage: any = "en";
  translation: any;

  checked = false;
  indeterminate = false;
  labelPosition: "before" | "after" = "after";
  disabled = false;

  onChangeLanguageSubs = new Subscription();
  onGetLanguageSubs = new Subscription();
  onLoadPDFSubs = new Subscription();
  onGetFileSubs = new Subscription();
  onGetEventByIDSubs = new Subscription();
  imageBaseURL = SETTINGS.IMAGE_BASE_URL;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: EventDTO,
    private translationService: TranslationService,
    private _service: ArtistService,
    private _eventService: EventService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private _commonService: CommonService
  ) {}
  // public selectedAppDate = this.datePipe.transform(
  //   this.apiService.selectedAppDate,
  //   "yyyy-MM-dd"
  // );
  ngOnInit(): void {
    //let date = this.datePipe.transform(this.selectedAppDate, 'yyyy-MM-dd');

    this.isShowPDF = this.data && this.data.eventCustomerPDFDTO && this.data.eventCustomerPDFDTO.signedPDFFileName;

    if (
      this.data &&
      this.data.eventImageList &&
      this.data.eventImageList.length > 0
    ) {
      this.data.eventImageList.forEach((s) => {
        if (s.imageURL) {
          s.profilePic = SETTINGS.IMAGE_BASE_URL + '/' + s.imageURL;
          // this.onGetFileSubs = this._commonService.getFile(s.imageURL).subscribe((data: any) => {
          //   const reader = new FileReader();
          //   reader.onload = (e) => (s.profilePic = e.target.result);
          //   reader.readAsDataURL(new Blob([data]));
          //   // let objectURL = "data:image/png;base64," + data;
          //   // this.profilePic = this.sanitizer.bypassSecurityTrustUrl(objectURL);
          //
          //   // var urlCreator = window.URL || window.webkitURL;
          //   // var imageUrl = urlCreator.createObjectURL(data);
          //   // this.profilePic = data;
          // });
        }
      });
    }
    // if (this.data.profilePicURL) {
    //   this._commonService
    //     .getFile(this.data.profilePicURL)
    //     .subscribe((data: any) => {
    //
    //       const reader = new FileReader();
    //       reader.onload = (e) => (this.profilePic = e.target.result);
    //       reader.readAsDataURL(new Blob([data]));
    //       // let objectURL = "data:image/png;base64," + data;
    //       // this.profilePic = this.sanitizer.bypassSecurityTrustUrl(objectURL);

    //       // var urlCreator = window.URL || window.webkitURL;
    //       // var imageUrl = urlCreator.createObjectURL(data);
    //       // this.profilePic = data;
    //     });
    // }

    this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
      this.selectedLanguage = res;
      this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
        this.translation = data.appointments_listing;
      });
    });

    // this.getById();
  }

  toSeconds(time_str) {
    return commonUtil.toSeconds(time_str);
  }

  GetTimeDuration(startTime: string, endTime: string) {
    return commonUtil.GetTimeDuration(startTime, endTime);
  }

  timeConvert(time) {
    return commonUtil.timeConvert(time);
  }

  getById() {
    this.onGetEventByIDSubs = this._eventService.getById(this.event.eventID).subscribe((result) => {
      if (result.status == "SUCCESS") {
        this.event = result.result;
      } else if (result.status == "FAILED") {
        result.appsErrorMessages.forEach((s) => {
          this.toastr.error(s.errorMessage);
        });
      } else {
        this.toastr.error("Someting went wrong during register user");
      }
    });
  }

  closeModal() {
    this.dialog.closeAll();
  }

  onClickImage($event: MouseEvent, event:any) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    const dialogRef = this.dialog.open(ViewUploadedImageComponent, {
      width: '70%',
      panelClass: 'custom-dialog-panel',
      data: {
        imageSrc: event.profilePic,
      }
    });
  }

  onViewValidatedPDF($event: MouseEvent) {
    if ($event) {
      $event.stopPropagation();
      $event.preventDefault();
    }

    if (this.data && this.data.eventCustomerPDFDTO && this.data.eventCustomerPDFDTO.signedPDFFileName) {
      let pdfURL = this.data.eventCustomerPDFDTO.signedPDFFileName;

      if (pdfURL !== null) {
        const dialogRef = this.dialog.open(ViewPdfComponent, {
          width: '70%',
          panelClass: 'custom-dialog-panel',
          data: {
            pdfURL: pdfURL,
            title: 'View PDF',
          }
        });
      } else {
        this.toastr.warning("PDF can't find");
        return;
      }
    } else {
      return;
    }
  }

  ngOnDestroy(): void {
    this.onChangeLanguageSubs.unsubscribe();
    this.onGetLanguageSubs.unsubscribe();
    this.onLoadPDFSubs.unsubscribe();
    this.onGetFileSubs.unsubscribe();
    this.onGetEventByIDSubs.unsubscribe();
  }
}
