import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslationService} from "../../../../../core/services/transalation.service";
import {MatCheckboxChange} from "@angular/material/checkbox";
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import {StorageService} from "../../../../../core/services/storage.service";
import {AddAdditionalInfoComponent} from "./add-additional-info/add-additional-info.component";
import {MatDialog} from "@angular/material/dialog";
import {CommonService} from "../../../../../core/services/common.service";
import {ToastrService} from "ngx-toastr";
import {commonUtil} from "../../../../../core/utils/commonUtil";
import {ConsentFormDto} from "../../../../../core/models/consent-form-dto";
import {Subscription} from "rxjs";
import {ConsentFormService} from "../../../../../core/services/consent-form.service";
import {ViewPdfComponent} from "../../../../schedule/view-pdf/view-pdf.component";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
    selector: 'app-add-edit-consent-form',
    templateUrl: './add-edit-consent-form.component.html',
    styleUrls: ['./add-edit-consent-form.component.scss']
})
export class AddEditConsentFormComponent implements OnInit, OnDestroy {
    addEditConsentForm: FormGroup;
    translation: any;
    selectedLanguage: any = "en";
    isItemOneChecked: string = 'N';

    // imageSrc: any = './assets/img/default-image.jpg'
    pdfFooter: any = 'Powered by INKDSTRY';

    additionalInformationArr: any = [];
    idxCount: any = 1;
    pdfURL: any;

    artistID: any;
    consentFormDto: ConsentFormDto = new ConsentFormDto();

    onConsentFormChangeSubs = new Subscription();
    onChangeLanguageSubs = new Subscription();
    onGetLanguageSubs = new Subscription();
    onUploadPDFFileSubs = new Subscription();
    onSaveConsentFormSubs = new Subscription();

    constructor(private formBuilder: FormBuilder,
                private translationService: TranslationService,
                public dialog: MatDialog,
                private _commonService: CommonService,
                private consentFormService: ConsentFormService,
                private toastr: ToastrService,
                private storageService: StorageService) {
    }

    ngOnDestroy(): void {
        this.onConsentFormChangeSubs.unsubscribe();
        this.onChangeLanguageSubs.unsubscribe();
        this.onGetLanguageSubs.unsubscribe();
        this.onUploadPDFFileSubs.unsubscribe();
        this.onSaveConsentFormSubs.unsubscribe();
    }

    ngOnInit(): void {
        this.artistID = commonUtil.getUserSession().artistID;

        this.onConsentFormChangeSubs = this.consentFormService.onConsentFormChange
            .subscribe(consentFormData => {
                this.consentFormDto = new ConsentFormDto(consentFormData);
            });

        this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
            this.selectedLanguage = res;
            this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
                this.translation = data.add_edit_consent_form;
            });
        });

        this.initForm();
    }

    initForm() {
        this.addEditConsentForm = this.formBuilder.group({
            title: [this.consentFormDto.title, Validators.compose([
                Validators.required
            ])],
            content: [this.consentFormDto.content, Validators.compose([
                Validators.required
            ])]
        });

        this.additionalInformationArr = this.consentFormDto.consentFormAddInfoList;
        this.pdfURL = this.consentFormDto.pdfURL;
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.addEditConsentForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    changeCheckbox($event: MatCheckboxChange, field: any) {

        switch (field) {
            case 'ITEM_ONE':
                $event.checked ? this.isItemOneChecked = 'Y' : this.isItemOneChecked = 'N';
                break;
        }
    }

    onSave($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        // let submitData = Object.assign({}, this.addEditConsentForm.getRawValue());
        // submitData.isItemOneChecked = this.isItemOneChecked;
        //
        // console.log(submitData);


    }

    isFormValid() {
        return this.addEditConsentForm.valid;
    }

    onImageCropped(file) {
        const formData: any = new FormData();
        formData.append("file", file, file['name']);

        //Fixme : Need to fix image uploading
        /*this.storageService.uploadImage(formData);*/
    }

    getImageUrl() {
        return null;
    }

    //Fixme : Need to fix image uploading
    /*selectFile($event: Event) {
        // @ts-ignore
        this.selectedFile = event.target.files[0];
    }*/

    async onPreviewPDF($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let formData = Object.assign({}, this.addEditConsentForm.getRawValue());

        let valArray = [];
        this.additionalInformationArr.forEach((data => {
            valArray.push(data.value);
        }))

        const docDefinition = {
            content: [
                // {
                //     image: await this.getBase64ImageFromURL(
                //         this.imageSrc
                //     ),
                //     fit: [150, 150],
                //     marginTop: 20
                // },
                {
                    text: 'Declaration Of Consent',
                    fontSize: 18,
                    marginTop: 20,
                    alignment: 'center',
                    color: '#000000'
                },
                {
                    text: formData.title,
                    style: 'sectionHeader',
                    marginTop: 20
                },
                {
                    text: formData.content,
                },
                {
                    text: 'Additional Information',
                    style: 'sectionHeader'
                },
                {
                    ul: valArray,
                },
            ],
            footer: {
                columns: [
                    {text: this.pdfFooter, alignment: 'center'}
                ]
            },
            styles: {
                sectionHeader: {
                    bold: true,
                    decoration: 'underline',
                    fontSize: 14,
                    margin: [0, 15, 0, 15]
                }
            }
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        //This will open PDF in new tab
        // pdfDocGenerator.open();

        pdfDocGenerator.getBase64((data) => {
            const byteCharacters = atob(data);

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'pdf'});
            const pdfSrc = blob;

            if (pdfSrc !== null) {
                const dialogRef = this.dialog.open(ViewPdfComponent, {
                    width: '70%',
                    panelClass: 'custom-dialog-panel',
                    data: {
                        pdfURL: pdfSrc,
                        isConsentForm: 'Y',
                        title: 'View Consent Form PDF',
                    }
                });
            } else {
                this.toastr.warning("PDF can't find");
                return;
            }
        });
    }

    async onUploadPDF($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let formData = Object.assign({}, this.addEditConsentForm.getRawValue());

        let valArray = [];
        this.additionalInformationArr.forEach((data => {
            valArray.push(data.value);
        }))

        const docDefinition = {
            content: [
                {
                    text: 'Declaration Of Consent',
                    fontSize: 18,
                    marginTop: 20,
                    alignment: 'center',
                    color: '#000000'
                },
                // {
                //     image: await this.getBase64ImageFromURL(
                //         this.imageSrc
                //     ),
                //     fit: [150, 150],
                //     marginTop: 20
                // },
                {
                    text: formData.title,
                    style: 'sectionHeader',
                    marginTop: 20
                },
                {
                    text: formData.content,
                },
                {
                    text: 'Additional Information',
                    style: 'sectionHeader'
                },
                {
                    ul: valArray,
                },
            ],
            footer: {
                columns: [
                    {text: this.pdfFooter, alignment: 'center'}
                ]
            },
            styles: {
                sectionHeader: {
                    bold: true,
                    decoration: 'underline',
                    fontSize: 14,
                    margin: [0, 15, 0, 15]
                }
            }
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getBase64((data) => {
            const request: any = new FormData();

            const byteCharacters = atob(data);

            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {type: 'pdf'});

            request.append('file', blob, 'test.pdf');

            this.onUploadPDFFileSubs = this._commonService.uploadPDFFile(request).subscribe((result) => {
                if (result) {
                    if (result.status == "SUCCESS") {
                        this.toastr.success("PDF uploaded successfully!");
                        this.pdfURL = result.result;

                        let submitData = Object.assign({}, this.addEditConsentForm.getRawValue());
                        submitData.pdfURL = this.pdfURL;
                        submitData.artistID = this.artistID
                        submitData.consentFormAddInfoList = this.additionalInformationArr;

                        this.onSaveConsentFormSubs = this.consentFormService.saveConsentForm(submitData).subscribe((result) => {
                            if (result) {
                                if (result.status == "SUCCESS") {
                                    this.toastr.success("Consent form Saved successfully");
                                } else if (result.status == "FAILED") {
                                    result.appsErrorMessages.forEach((s) => {
                                        this.toastr.error(s.errorMessage);
                                    });
                                } else {
                                    this.toastr.error("Something went wrong, Please try again");
                                }
                            } else {
                                this.toastr.error("Something went wrong, Please try again");
                            }
                        });

                    } else if (result.status == "FAILED") {
                        result.appsErrorMessages.forEach((s) => {
                            this.toastr.error(s.errorMessage);
                        });
                    } else {
                        this.toastr.error("Something went wrong");
                    }
                } else {
                    this.toastr.error("Something went wrong");
                }
            });
        });
    }

    getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const dataURL = canvas.toDataURL('image/png');

                resolve(dataURL);
            };

            img.onerror = error => {
                reject(error);
            };

            img.src = url;
        });
    }

    onClickAdditionalInformationAdd($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        const dialogRef = this.dialog.open(AddAdditionalInfoComponent, {
            width: '70%',
            panelClass: 'custom-dialog-panel',
            data: {}
        });

        const dialogSubs = dialogRef.afterClosed()
            .subscribe((response) => {
                if (response) {
                    if (response.displayOrder == null) {
                        response.displayOrder = this.idxCount;
                        this.idxCount++;
                        this.additionalInformationArr.push(response);
                    }
                }
                dialogSubs.unsubscribe();
            });
    }

    onClickRemove($event: MouseEvent, number: number) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        this.additionalInformationArr = this.additionalInformationArr.filter(
            function (obj) {
                return obj.displayOrder != number;
            });
    }
}
