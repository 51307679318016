import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {commonUtil} from "../utils/commonUtil";
import {ToastrService} from "ngx-toastr";
import {SETTINGS} from "../../main/commons.settings";

@Injectable({
    providedIn: 'root'
})
export class BillingSubscriptionGuard implements CanActivate {

    constructor(private toasterService: ToastrService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!commonUtil.hasSubscribed()) {
            this.toasterService.warning(SETTINGS.TOAST_MESSAGE.SUBSCRIPTION_VALIDATE_MESSAGE);
            return false;
        } else {
            return commonUtil.isLoggedAdmin();
        }
    }
}
