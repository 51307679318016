import {Injectable} from '@angular/core';
import {Observable, Subject, Subscription} from "rxjs";
import {ApiService} from "./api.service";

@Injectable({
    providedIn: 'root'
})
export class ContactUsService {

    baseUrl: string = "/contact-us/";
    onsubmitContactUs: Subject<any> = new Subject<any>();

    constructor(private api: ApiService) {
    }

    public submitContactUs(dataRQ: any): Subscription {
        return this.api.post<any>(
            this.baseUrl + "submitContactUs",
            dataRQ
        ).subscribe((response: any) => {
            this.onsubmitContactUs.next(response);
        })
    }
}
