import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {ArtistComponent} from './artist/artist.component';
import {AddArtistComponent} from "./add-artist/add-artist.component";
import {ArtistSessionHistoryComponent} from "./artist-session-history/artist-session-history.component";
import {BillingSubscriptionGuard} from "../../core/guards/billing-subscription.guard";

const routes: Routes = [
    {path: 'artist',
        component: ArtistComponent,
        canActivate: [BillingSubscriptionGuard]
    },
    {path: 'add-artist',
        component: AddArtistComponent,
        canActivate: [BillingSubscriptionGuard]
    },
    {path: 'artist-session-history',
        component: ArtistSessionHistoryComponent,
        canActivate: [BillingSubscriptionGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ArtistRoutingModule {
}
