export class AftercareDto {
    afterCareID: number;
    artistID: number;
    subject: string;
    body: string;

    constructor(aftercareDto?) {
        aftercareDto = aftercareDto || {};
        this.afterCareID = aftercareDto.afterCareID || '';
        this.artistID = aftercareDto.artistID || '';
        this.subject = aftercareDto.subject || '';
        this.body = aftercareDto.body || '';
    }
}
