<h1 mat-dialog-title>Session Details</h1>
<div mat-dialog-content>
  <div class="row artists-head">
    <div class="col-md-12">
      <div class="row">
        <div class="col-sm-7 d-flex">
          <div class="state-wrapper d-flex align-items-center">
            <div
              class="state"
              [ngClass]="{ selected: data.validated != 'N' }"
            ></div>
            <span>Validated</span>
          </div>
          <div class="state-wrapper d-flex align-items-center">
            <div
              class="state"
              [ngClass]="{ selected: data.noShow != 'N' }"
            ></div>
            <span>Now Show</span>
          </div>
          <div class="state-wrapper d-flex align-items-center">
            <div
              class="state"
              [ngClass]="{ selected: data.cancelled != 'N' }"
            ></div>
            <span>Cancelled</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-2" style="text-align: left" *ngIf="isShowPDF">
          <img class="img-icon"
               (click)="onViewValidatedPDF($event)"
               [matTooltip]="'View PDF'"
               src="assets/img/eye.png"
               alt=""/>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-sm-3">
          <h5 class="label">Artist</h5>
          <p class="value">
            {{ data.userDTO.firstName }} {{ data.userDTO.lastName }}
          </p>
        </div>

        <div class="col-sm-3">
          <h5 class="label">Client</h5>
          <p class="value">
            {{ data.customerDTO.firstName }} {{ data.customerDTO.lastName }}
          </p>
        </div>

        <div class="col-sm-3">
          <h5 class="label">Price</h5>
          <p class="value">{{ data.cost }}</p>
        </div>

        <div class="col-sm-3">
          <h5 class="label">Date</h5>
          <p class="value">{{ data.startDateStr }}</p>
        </div>
      </div>
      <div class="row mt-3 mb-3">
        <div class="col-sm-3">
          <h5 class="label">Tatto Location</h5>
          <p class="value">{{ data.tattooLocation }}</p>
        </div>
        <div class="col-sm-3">
          <h5 class="label">Email</h5>
          <p class="value">{{ data.customerDTO.email }}</p>
        </div>
        <div class="col-sm-3">
          <h5 class="label">Time</h5>
          <p class="value">{{ data.startTime }}</p>
        </div>
      </div>
      <div class="mb-3" *ngIf="data.eventImageList">
        <h5 class="label">Images</h5>
        <div class="d-flex">
          <img
            *ngFor="let event of data.eventImageList"
            [src]="event.profilePic || 'assets/img/Logo.png'"
            (click)="onClickImage($event, event)"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions style="text-align: right; color: white">
  <button class="btn btn-default add-btn" mat-dialog-close>Close</button>
</div>
