export class ConsentFormEmailDto {
    consentFormEmailID: number;
    artistID: number;
    subject: string;
    body: string;

    constructor(consentFormEmailDto?) {
        consentFormEmailDto = consentFormEmailDto || {};
        this.consentFormEmailID = consentFormEmailDto.consentFormEmailID || '';
        this.artistID = consentFormEmailDto.artistID || '';
        this.subject = consentFormEmailDto.subject || '';
        this.body = consentFormEmailDto.body || '';
    }
}
