export class UserDTO {
    artistID: number;
    firstName: string;
    lastName: string;
    username: string;
    email: string;
    password: string;
    role: string;
    createDate: Date;
    accessToken?: any;
    refreshToken?: any;
    profilePicURL: string;
    consentFormStatus: string
    sessionReminderStatus: string
    aftercareStatus: string

    constructor(userDto?) {
        userDto = userDto || {};
        this.artistID = userDto.artistID || '';
        this.firstName = userDto.firstName || '';
        this.lastName = userDto.lastName || '';
        this.username = userDto.username || '';
        this.email = userDto.email || '';
        this.password = userDto.password || '';
        this.role = userDto.role || '';
        this.createDate = userDto.createDate || '';
        this.accessToken = userDto.accessToken || '';
        this.refreshToken = userDto.refreshToken || '';
        this.profilePicURL = userDto.profilePicURL || '';
        this.consentFormStatus = userDto.consentFormStatus || 'INA';
        this.sessionReminderStatus = userDto.sessionReminderStatus || 'INA';
        this.aftercareStatus = userDto.aftercareStatus || 'INA';
    }
}
