import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslationService} from "../../core/services/transalation.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {AccountService} from "../../core/services/account.service";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-forget-password',
    templateUrl: './forget-password.component.html',
    styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {

    selectedLanguage: any = "en";
    translation: any;

    forgetPasswordForm: FormGroup;
    onForgetPasswordSubmitSubs = new Subscription();
    onChangeLanguageSubs = new Subscription();
    onGetLanguageSubs = new Subscription();

    constructor(private translationService: TranslationService,
                private formBuilder: FormBuilder,
                private accountService: AccountService,
                private toasterService: ToastrService) {
    }

    ngOnInit(): void {
        this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
            this.selectedLanguage = res;
            this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
                this.translation = data.forgot_password;
            });
        });

        this.onForgetPasswordSubmitSubs = this.accountService.onForgetPasswordSubmit
            .subscribe(response => {
                if (response) {
                    if (response.status == "SUCCESS") {
                        this.toasterService.success("Forget password link sent your Email. Please check emails.");
                    } else if (response.status == "FAILED") {
                        response.appsErrorMessages.forEach((s) => {
                            this.toasterService.error(s.errorMessage);
                        });
                    } else {
                        this.toasterService.error("Something went wrong, Please try again");
                    }
                } else {
                    this.toasterService.error("Something went wrong, Please try again");
                }
            });

        this.initForm();
    }

    initForm() {
        this.forgetPasswordForm = this.formBuilder.group({
            email: ['', Validators.compose([
                Validators.required
            ])]
        });
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.forgetPasswordForm.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    isFormValid() {
        return this.forgetPasswordForm.valid;
    }

    onConfirm($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let formData = Object.assign({}, this.forgetPasswordForm.getRawValue());

        this.accountService.forgetPasswordConfirm(formData);
    }

    ngOnDestroy(): void {
        this.onForgetPasswordSubmitSubs.unsubscribe();
        this.onChangeLanguageSubs.unsubscribe();
        this.onGetLanguageSubs.unsubscribe();
    }
}
