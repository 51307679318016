<div class="billing-area-start billing-bg">

    <div class="row pos-table">
        <div class="col-md-8 ">
            <div class="billing-Order-history">
                <h4>{{translation?.listing_title}}</h4>
                <div class="order-table">
                    <table id="myTable">
                        <thead>
                        <tr>
                            <th>{{translation?.grid_columns[0]}} <img class="sort" src="assets/img/up-dwn.png" alt=""></th>
                            <th>{{translation?.grid_columns[1]}} <img class="sort"  src="assets/img/up-dwn.png" alt=""></th>
                            <th>{{translation?.grid_columns[2]}} <img class="sort"  src="assets/img/up-dwn.png" alt=""></th>
                            <th>{{translation?.grid_columns[3]}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let transaction of transactions">
                            <td><span>{{transaction.createDate | date :'M/d/yy, h:mm a'}}</span></td>
                            <td><span>{{transaction.amount / 100}}</span></td>
                            <td><span>{{transaction.planType}}</span></td>
                            <td><span><a href="#">{{translation?.btn_download}}</a></span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <div class="Your-Plan-right">
                <h6>{{translation?.plan_card.title}}</h6>

                <h3 >{{planType ? planType : '-'}}</h3>
                <div style="display: flex; justify-content: space-between;">
                    <p class="review-txt">{{translation?.plan_card.description}} 25-01-2023 </p>
                </div>
                <div style="display: flex; justify-content: space-between;">
                    <a class="cancel-sub" href="javascript:;" (click)="cancelSubscription()" *ngIf="planType"> {{translation?.plan_card.btn_cancel}}</a> &nbsp;
                    <a class="cancel-sub" href="javascript:;" [routerLink]="'subscriptions'" *ngIf="planType"> Upgrade Subscription</a> &nbsp;
                    <a class="cancel-sub" href="javascript:;" [routerLink]="'subscriptions'" *ngIf="!planType">Buy Subscription</a>
                </div>                
            </div>

            <div class="Payment-Method-left">
                <h6> {{translation?.payment_card.title}}</h6>
                <p>{{translation?.payment_card.description}}</p>

                <div style="display:flex;justify-content: space-between;">
                   
                    <div><a class="remove-btn" (click)="routeToStripeBilling()">{{translation?.payment_card.btn_remove}}</a></div>
                </div>
            </div>
        </div>
    </div>
</div>


