<div class="container-fluid">

    <div class="row consent-form-image-upload-header ">

        <div class="col-md-12">
            <div class="row">
                <div class="col-md-8">
                    <h2 style="margin-top: 12px">Upload Consent Form Image</h2>
                </div>
                <div class="col-md-4">
                    <a class="close-btn" (click)="onCancelClick()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row image-div">
        <div>
            <div class="content w-99-p" fxFlexAlign="left left" fxLayout="row">
                <input
                        (change)="selectFile($event)"
                        class="p-bottom-6"
                        name="file"
                        type="file"
                >
            </div>

            <div [class.hide]="!isImageLoaded"
                 class="w-100-p"
                 fxFlexAlign="start start"
                 fxLayout="row"
                 fxLayout.sm="column wrap"
                 fxLayout.xs="column wrap"
                 fxLayoutGap="10px">
                <image-cropper
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded()"
                        (loadImageFailed)="loadImageFailed()"
                        [aspectRatio]="aspectRatio"
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="maintainAspectRatio"
                        [resizeToWidth]="resizeToWidth"
                        format="jpeg"
                        imageQuality="80"
                ></image-cropper>
                <div fxLayout="column" fxLayoutAlign="center center">
                    <img [src]="croppedImage" class="cropped-image"/>
                    <p style="color: white; margin-left: 6px">Preview</p>
                </div>

            </div>

            <div *ngIf="fileUploadError.hasError">
                <p style="color: red; margin-left: 6px">
                    {{fileUploadError.errorMessage}}
                </p>
            </div>
        </div>
        <div class="kt-portlet__foot kt-portlet__foot--fit kt-portlet__no-border">
            <div class="w-100-p p-12 p-top-24" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
                <div class="col-md-12" style="text-align: end">
                    <button class="btn btn-default consent-form-image-save-btn"
                            [disabled]="!isValidUpload()"
                            (click)="onSave()"
                    >
                        Upload
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
