<div class="container-fluid bg-del">
    <div>
        <a (click)="  closeModal()" class="cross-btn"> <img src="assets/img/icon-cancel.png" alt=""></a>
    </div>
    <div>
        <p class="sure-txt">
            {{translation?.message}}
        </p>
    </div>

    <div style="display: flex;justify-content: center;">
        <button (click)="  closeModal()" type="button" class="btn btn-primary btn-yes">{{translation?.btn_confirm}}</button>
    </div>

</div>

