<div class="row consent-form-head">
    <div class="col-md-12 bg-clients">
        <div class="table-box">
            <div class="row">
                <div class="col-md-12 clients-list">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="table-heading">Consent Form Setup</p>
                        </div>
                    </div>
                </div>

                <div class="row m-l-r-15">
                    <form [formGroup]="addEditConsentForm" autocomplete="off" class="kt-form" id="consent-form">
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 20px">
                                <input type="text"
                                       formControlName="title"
                                       maxlength="128"
                                       class="form-control consent-form-input"
                                       placeholder="{{translation?.title_input}}"
                                       autocomplete=" new-password">
                                <div *ngIf="isControlHasError('title','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('title','required')">
                                        Title is required
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12" style="margin-top: 20px">
                                <textarea type="text"
                                          formControlName="content"
                                          class="form-control consent-form-input"
                                          rows="8"
                                          maxlength="512"
                                          placeholder="{{translation?.content}}"
                                          autocomplete=" new-password">
                                </textarea>
                                <div *ngIf="isControlHasError('content','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('content','required')">
                                        Content is required
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6" style="margin-top: 20px">
                                <h3 style="color: white; font-weight: 500">
                                    Additional Information
                                </h3>
                            </div>
                            <div class="col-md-6" style="margin-top: 20px; text-align: end">
                                <button class="btn btn-default additional-info-btn"
                                        style="margin-right: 10px"
                                        (click)="onClickAdditionalInformationAdd($event)"
                                >
                                    Add
                                </button>
                            </div>
                        </div>

                        <ng-container *ngFor="let addInfo of additionalInformationArr">

                            <div class="row">
                                <div class="col-md-12" style="margin-top: 20px">
                                    <div class="col-md-12 consent-form-input">
                                        <div class="col-md-11">
                                            <p style="color: white; padding: 8px; font-size: 14px">
                                                {{addInfo.value}}
                                            </p>
                                        </div>
                                        <div class="col-md-1">
                                            <button class="btn btn-default additional-remove-btn"
                                                    style="margin-right: 10px"
                                                    (click)="onClickRemove($event, addInfo.displayOrder)"
                                            >
                                                Remove
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </form>

                </div>

                <div class="row m-l-r-15" style="margin-top: 20px">
                    <div class="col-md-12" style="text-align: end">
                        <button class="btn btn-default consent-form-save-btn"
                                style="margin-right: 10px"
                                [disabled]="!isFormValid()"
                                (click)="onPreviewPDF($event)"
                        >
                            {{translation?.btn_preview}}
                        </button>
                        <button class="btn btn-default consent-form-save-btn"
                                [disabled]="!isFormValid()"
                                (click)="onUploadPDF($event)"
                        >
                            {{translation?.btn_upload}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>