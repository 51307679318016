<div class="container-fluid">

    <div class="row additional-info-header">
        <div class="col-md-12">
            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <h2 style="margin-top: 12px">Confirm Status Update</h2>

                    <a class="close-btn" (click)="onCancelClick()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row m-l-r-15">
        <p style="color: white; font-size: 15px; margin-top: 15px">
            Are you sure want to {{content}} ?
        </p>
    </div>

    <div class="row" style="margin-top: 15px">
        <div style="text-align: end">
            <button class="btn btn-default add-info-btn"
                    (click)="onConfirm($event)"
            >
                Confirm
            </button>
        </div>
    </div>
</div>
