<h1 mat-dialog-title>Artist Detail</h1>
<div mat-dialog-content>
  <div class="row artists-head">
    <div class="col-md-12">
      <div class="row">
        <div class="col-sm-6 col-md-3">
          <img [src]="profilePic || 'assets/img/Logo.png'" style="width: 72px; height: 72px;" />
        </div>
        <div class="col-sm-6 col-md-3 mt-5 pt-1">
          <h5 class="label">Name</h5>
          <p class="value">{{ data.firstName }} {{ data.lastName }}</p>
        </div>
        <div class="col-sm-6 col-md-3 mt-5 pt-1">
          <h5 class="label">Email</h5>
          <p class="value">{{ data.email }}</p>
        </div>
        <div class="col-sm-6 col-md-3 mt-5 pt-1">
          <h5 class="label">User Name</h5>
          <p class="value">{{ data.username }}</p>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-12 session-table">
          <table id="table">
            <thead>
              <tr>
                <th>Appointment Date</th>
                <th>Time</th>
                <th>Time Duration</th>
                <th>Appointment Fees</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let item of filterEvents
                    | paginate : { itemsPerPage: 5, currentPage: qSH }
                ">
                <td>
                  <span>{{ item.startDateStr }}</span>
                </td>
                <td>
                  <span>{{ timeConvert(item.startTime) }} to
                    {{ timeConvert(item.endTime) }}
                  </span>
                </td>
                <td>
                  <span>{{
                    GetTimeDuration(item.startTime, item.endTime)
                    }}
                    Hours</span>
                </td>
                <td>
                  <span class="cell-no">${{ item.cost }} </span>
                </td>
                <td>
                  <span class="dropdown">
                    <a (click)="viewModal(item)" class="dropdown-toggle" data-toggle="dropdown">
                      <img class="eye-icon" src="../../../../assets/img/eye.png" alt="" />
                    </a>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
          <pagination-controls class="pagination-pos" (pageChange)="qSH = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="btn btn-default add-btn" mat-dialog-close>
    Close
  </button>
</div>