<div class="row client-head">
    <div class=" col-md-12 bg-clients">
        <div class="table-box">
            <div class="row">
                <div class="col-12">
                    <div class="d-flex align-items-center justify-content-between flex-wrap">

                        <div class="me-2">
                            <p class="table-heading"> {{ translation?.title }}</p>
                        </div>

                        <div class="">
                            <div>
                                <input type="search" class="search-styl mb-2" placeholder="Search" [(ngModel)]="term">
                                <button class="btn btn-default add-btn" (click)="openModal()">{{ translation?.btn_add
                                    }}</button>
                            </div>
                        </div>


                    </div>
                </div>

            </div>


            <div class="row">
                <div class="col-md-12 col-sm-12 col-xs-12 listing-table">
                    <div class="table-responsive border-0">
                        <table id="client-table">
                            <thead>
                                <tr>
                                    <th>{{ translation?.grid_columns[0] }} <img class="up-down"
                                            src="assets/img/up-dwn.png" alt=""></th>
                                    <th>{{ translation?.grid_columns[1] }} <img class="up-down"
                                            src="assets/img/up-dwn.png" alt=""></th>
                                    <th>{{ translation?.grid_columns[2] }} <img class="up-down"
                                            src="assets/img/up-dwn.png" alt=""></th>
                                    <th>{{ translation?.grid_columns[3] }}<img class="up-down"
                                            src="assets/img/up-dwn.png" alt=""></th>
                                    <th>{{ translation?.grid_columns[4] }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    *ngFor="let item of dataList|filter:term| paginate: { itemsPerPage: 5,currentPage: q }">

                                    <td><span>{{item.firstName}}</span></td>
                                    <td><span>{{item.lastName}} </span></td>
                                    <td><span> {{item.email}}</span></td>
                                    <td><span class="cell-no">{{item.telNumber}} </span></td>
                                    <td><span class="dropdown">
                                            <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                                                <img class="expend" src="assets/img/expend.png" alt="">
                                            </a>
                                            <ul class="dropdown-menu drop-styl">
                                                <li><a (click)="viewModal(item)"> <i class="fa fa-eye"
                                                            aria-hidden="true"></i>{{actions[0]}}</a></li>
                                                <li><a (click)="editModal(item)"><i class="fa fa-pencil"
                                                            aria-hidden="true"></i>{{actions[1]}}</a></li>
                                                <!-- <li><a (click)="deleteModel(item)"><i class="fa fa-trash-o"
                                                            aria-hidden="true"></i>{{actions[2]}}</a></li> -->
                                            </ul>
                                        </span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <pagination-controls class="pagination-pos" (pageChange)="q = $event"></pagination-controls>
                </div>
            </div>
        </div>
    </div>
</div>