<section class="web-contact-hero">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-md-6 col-lg-8">
                <div class="contact-left-text d-flex">
                    <h2 class="contact-before-line">Contact<span> Us</span></h2>
                    <div class="d-flex flex-column justify-content-center w-100">
                        <hr class="white-line white-line__long">
                        <hr class="white-line white-line__short">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- hero-area-end -->

<!-- Get In-area-start -->
<section class="get-In-area">
    <div class="container">
        <div class="form-title">
            <h4>Get In</h4>
            <h2>touch with us</h2>
        </div>
        <div class="form-area">
            <form [formGroup]="contactUsForm" autocomplete="off" class="kt-form" id="consent-form">
                <div class="row">
                    <div class="col-md-6 col-lg-4">
                        <input type="text"
                               style="height: 30px"
                               formControlName="name"
                               maxlength="128"
                               class="form-control contact-us-form-input"
                               placeholder="Name">
                        <div *ngIf="isControlHasError('name','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('name','required')">
                                        Name is required
                                    </span>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                        <input type="text"
                               formControlName="email"
                               maxlength="255"
                               class="form-control contact-us-form-input"
                               placeholder="Email">
                        <div *ngIf="isControlHasError('email','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('email','required')">
                                        Email is required
                                    </span>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-4">
                        <input type="text"
                               formControlName="phone"
                               maxlength="20"
                               class="form-control contact-us-form-input"
                               placeholder="Phone">
                        <div *ngIf="isControlHasError('phone','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('phone','required')">
                                        Phone is required
                                    </span>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-12" style="margin-top: 40px !important;">
                        <textarea type="text"
                                  style="height: 100px!important;"
                                  formControlName="message"
                                  class="form-control contact-us-form-input"
                                  rows="5"
                                  cols="30"
                                  maxlength="1024"
                                  placeholder="Message">
                                </textarea>
                    </div>
                </div>
                <div class="get-submit">
                    <button (click)="onContactUsSubmit($event)">Submit</button>
                </div>
            </form>
        </div>
    </div>
</section>
<!-- .price-box-area-end -->

<!-- map-area-start -->
<section class="map-area">
    <div class="container">
        <div class="map-item-bg">
            <div class="row g-1">
                <div class="col-md-6 col-lg-6">
                    <div class="map-item">
                        <h3>Contact Us</h3>
                        <ul>
                            <li><span><i class="fa-solid fa-phone"></i></span> +353 83 479 9896</li>
                            <li><span><i class="fa-regular fa-envelope"></i></span> support@inkdstry.com</li>
                            <li><span><i class="fa-solid fa-house"></i></span> Navan, Ireland</li>
                        </ul>
                        <p>
                            <a href="#"><i class="fa-brands fa-linkedin"></i></a>
                            <a href="https://www.facebook.com/profile.php?id=100092216798515"><i
                                    class="fa-brands fa-facebook-f"></i></a>
                            <a href="https://www.instagram.com/inkdstry/"><i class="fa-brands fa-instagram"></i></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- map-area-end -->

<script>
    (function ($) {
        $(window).on("load", function () {
            $(".content").mCustomScrollbar();
        });
    })(jQuery);

    (function ($) {
        $(window).on("load", function () {
            $(".content").mCustomScrollbar();
        });
    })(jQuery);
</script>
