import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ClientComponent } from "./client/client.component";
import { ClientSessionHistoryComponent } from "./client-session-history/client-session-history.component";
import { AddClientComponent } from "./add-client/add-client.component";
import {BillingSubscriptionGuard} from "../../core/guards/billing-subscription.guard";


const routes: Routes = [
    { path: 'client',
      component: ClientComponent,
      canActivate:[BillingSubscriptionGuard]
     },
    { path: 'add-client' ,
        component : AddClientComponent,
        canActivate:[BillingSubscriptionGuard]
    },
    { path: 'client-session-history' ,
        component : ClientSessionHistoryComponent,
        canActivate:[BillingSubscriptionGuard]
    }
];

@NgModule({
    imports: [
        RouterModule.forChild(routes)
    ],
    exports: [RouterModule]
})
export class ClientRoutingModule { }
