import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ChatComponent } from './chat/chat.component';
import { BillingSubscriptionGuard } from 'app/core/guards/billing-subscription.guard';

const routes: Routes = [ { path: 'chat',
component: ChatComponent,
canActivate:[BillingSubscriptionGuard]
},];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ChatRoutingModule { }
