<section class="templates-area">
    <div class="row">
        <div class="col-md-4 ">
            <div class="templates-item">
                <div class="temp-title">
                    <span>Consent form</span>
                </div>
                <div class="temp-bodys">
                    <img src="./assets/img/template1.png" class="img-fluid" alt="">
                    <ul>
                        <li><a href="#/template/consent-form" class="add-edit-btn">{{translation[3]}}</a></li>
                        <li><a href="#/template/consent-form-email" class="add-edit-btn">{{translation[4]}}</a></li>
                        <li *ngIf="consentFormStatus === 'INA'" (click)="changeStatus($event, 'CONSENT_FORM', 'ACT')">
                            <a class="tem-active">{{translation[1]}}</a>
                        </li>
                        <li *ngIf="consentFormStatus === 'ACT'" (click)="changeStatus($event, 'CONSENT_FORM', 'INA')">
                            <a class="tem-inactive">{{translation[2]}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="templates-item">
                <div class="temp-title">
                    <span>Session reminder</span>
                </div>
                <div class="temp-bodys">
                    <img src="./assets/img/template2.png" class="img-fluid" alt="">
                    <ul>
                        <li><a href="#/template/session-reminder" class="add-edit-btn">{{translation[0]}}</a></li>
                        <li *ngIf="sessionReminderStatus === 'INA'"
                            (click)="changeStatus($event, 'SESSION_REMINDER', 'ACT')">
                            <a class="tem-active">{{translation[1]}}</a>
                        </li>
                        <li *ngIf="sessionReminderStatus === 'ACT'"
                            (click)="changeStatus($event, 'SESSION_REMINDER', 'INA')">
                            <a class="tem-inactive">{{translation[2]}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <div class="templates-item">
                <div class="temp-title">
                    <span>Aftercare</span>
                </div>
                <div class="temp-bodys">
                    <img src="./assets/img/template3.png" class="img-fluid" alt="">
                    <ul>
                        <li><a href="#/template/aftercare" class="add-edit-btn">{{translation[0]}}</a></li>
                        <li *ngIf="aftercareStatus === 'INA'" (click)="changeStatus($event, 'AFTERCARE', 'ACT')">
                            <a class="tem-active">{{translation[1]}}</a>
                        </li>
                        <li *ngIf="aftercareStatus === 'ACT'" (click)="changeStatus($event, 'AFTERCARE', 'INA')">
                            <a class="tem-inactive">{{translation[2]}}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>