
export const environment = {
  production: false,
  PROTOCOL: "http",

  // Stripe keys
  // stripeKey: 'pk_test_51MBwIULUDXVZUnKjYBuywfUnJOQBlzw0yia2mqJwVTZfLkRn6n48KVEsmCyPntPOXGa4ZFycNNhFMTnJ9dCgOjMG00Kk3SjDvP',
  stripeKey: 'pk_live_51MBwIULUDXVZUnKjIXnMdBBUHvvJ2nuDqjnGFAidNfKOr4VoSUfJzytXRUxXqPJRpq98eciyc0A87ucZvrP7NU8k00LkQESXfI',


  // Base urls
  // baseURL: "http://localhost:8080/api",
  baseURL: "https://api.inkdstry.com/api",

};
