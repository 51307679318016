import {CustomerDTO} from "./customerDto";
import {EventCustomerPDFDTO} from "./eventCustomerPDFDto";
import {UserDTO} from "./userDto";

export class EventDTO {
    eventID: number;
    artistID: number;
    userDTO: UserDTO;
    customerID: number;
    customerDTO: CustomerDTO;
    startTime: string;
    endTime: string;
    cost: number;
    imageURL: string;
    startDateStr: string;
    endDateStr: string;
    tattooLocation: string;
    comments: string;
    cancelled: string;
    noShow: string;
    validated: string;
    eventCustomerPDFDTO: EventCustomerPDFDTO;
    eventImageList: EventImageDTO[] = [];

    constructor(eventDto?) {
        eventDto = eventDto || {};

        this.eventID = eventDto.eventID || '';
        this.artistID = eventDto.artistID || '';
        this.userDTO = eventDto.userDTO || null;
        this.customerID = eventDto.customerID || '';
        this.customerDTO = eventDto.customerDTO || null;
        this.startTime = eventDto.startTime || this.getTime();
        this.endTime = eventDto.endTime || this.getTime();
        this.cost = eventDto.cost || '';
        this.startDateStr = eventDto.startDateStr || this.getDate();
        this.endDateStr = eventDto.endDateStr || this.getDate();
        this.tattooLocation = eventDto.tattooLocation || '';
        this.comments = eventDto.comments || '';
        this.cancelled = eventDto.cancelled || 'N';
        this.noShow = eventDto.noShow || 'N';
        this.validated = eventDto.validated || 'N';
        this.eventCustomerPDFDTO = eventDto.eventCustomerPDFDTO || null;
        this.eventImageList = eventDto.eventImageList || [];
    }

    //'2023-06-01'
    getDate() {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
        let currentYear = date.getFullYear();

        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

        return currentDate;
    }

    //'08:34:33'
    getTime() {
        const date = new Date();

        let currentHour = String(date.getHours()).padStart(2, '0');
        let currentMinutes = String(date.getMinutes()).padStart(2, '0');

        let currentTime = `${currentHour}:${currentMinutes}:00`;

        return currentTime;
    }
}

export class EventImageDTO {
    eventImageID: number;
    imageURL: string;
    profilePic?: any;
}
