<section class="web-hero-area">
    <div class="container h-100">       
        <div class="row h-100 justify-content-center align-items-center">
            <div class="col-md-6 col-lg-7">
                <div class="web-hero-left-text">
                    <h2>Rule The</h2>
                   <h2><span>INKDSTRY</span> <span class="white-text"> Like A Pro</span></h2>
                    <p>
                        The Premier CRM for Tattoo Studios
                    </p>
                    <a [routerLink]="['/pricing']">Try it now</a>
                </div>
            </div>
            <div class="col-md-6 col-lg-5">
                <!-- <div class="web-hero-right-item">
                    <div class="">
                        <img src="./assets/websiteimg/mobile.png" class="img-fluid" alt="" />
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</section>
<!-- hero-area-end -->

<!-- card-area-start -->
<section class="web-card-area">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="web-card-item">
                    <img src="./assets/websiteimg/i-1.png" class="img-fluid" alt="" />
                    <h2>100% Cloud-based Solution</h2>
                    <p>
                        Access Inkdstry from anywhere, at any time.
                        Our cloud-based system means zero in-house
                        management and data security
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="web-card-item">
                    <img src="assets/websiteimg/i.png" class="img-fluid" alt="" />
                    <h2>Complete Session History</h2>
                    <p>
                        Inkdstry keeps detailed records of every session.
                        You can easily access session histories and client information at any time
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="web-card-item">
                    <img src="assets/websiteimg/i3.png" class="img-fluid" alt="" />
                    <h2>Real-time Analytics</h2>
                    <p>
                        Access detailed reports, analytics, and insights
                        that help you make informed business decisions
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- card-area-end -->
<!-- Integrated Section Start  -->
<section class="integrated-card-area">
    <div class="container">
        <div class="web-business-title integrated-card-content">
            <h3>We Are Fully </h3>
            <h2>integrable with</h2>
        </div>
        <div class="d-flex align-items-center flex-wrap justify-content-center">

            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/Google_Calendar_icon_(2020).svg.png" class="img-fluid m-0" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Google Calendar</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/facebook-icon.png" class="img-fluid- m-0" width="51" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Facebook</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/1200px-Instagram_icon.png" class="img-fluid- m-0" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Instagram</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/pngtree-whatsapp-mobile-software-icon-png-image_6315991.png" class="img-fluid- m-0" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">SMS</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/wix_logo_icon_168759.png" class="img-fluid- m-0" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Wix</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/2560px-Zapier_logo.svg.png" class="img-fluid- m-0" width="51" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Zapier</h2>
                </div>
            </div>
            <div class="integrated-card-wrapper">
                <div class="web-card-item">
                    <div class="circle-icon-cs">
                        <img src="../../../assets/img/wordpress-logo-png-transparent-wordpress-logo-images-pluspng-6.png" class="img-fluid- m-0" alt="" />
                    </div>
                    <h2 class="text-center integrated-card-title">Wordpress</h2>
                </div>
            </div>
           
         
        </div>
    </div>
</section>

<!-- Integrated Section End  -->
<!-- your business grow start -->
<section class="web-business-area">
    <div class="container">
        <div class="web-business-title">
            <h3>Inkdstry</h3>
            <h2>Your Growth Partner</h2>
            <a [routerLink]="['/pricing']">Start Today!</a>
        </div>
    </div>
</section>
<!-- your business grow end -->
<div class="web-business-dashboard">
    <div class="container">
        <div class="web-dashboard-img">
            <img src="assets/websiteimg/business-dashboard.webp" class="img-fluid" alt="" />
        </div>
    </div>
</div>
<!-- Clients Like area-start -->
<section class="web-client-like-area">
    <div class="container">
        <div class="web-client-like-title">
            <p>
                <span>Digitise your consent and liability forms</span><br />
                streamline your day-to-day studio management <br/>
            </p>
        </div>
        <div class="row">
            <div class="col-md-6 col-lg-4">
                <div class="web-client-box-item">
                    <h3>E-sign Consent Forms</h3>
                    <p>
                        With Inkdstry, you can easily create and send automated personalized
                        e-signature consent forms to your clients
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="web-client-box-item">
                    <h3>Session Reminders</h3>
                    <p>
                        Be Sure Of Your Schedule, our system sends automated
                        session reminders to minimize no-shows
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="web-client-box-item">
                    <h3>Automated Aftercare</h3>
                    <p>
                        We care for your clients even after their appointment is over with our digital aftercare.
                        Ensuring the best client care
                    </p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Clients Like area-end -->

<!-- Keep Your Customers area -->
<section class="web-customer-area">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-lg-6">
                <div class="web-customer-left pe-5">
                    <img src="assets/websiteimg/cous-img.webp" class="img-fluid" alt="" />
                </div>
            </div>
            <div class="col-md-6 col-lg-6">
                <div class="web-customer-right">
                    <h2>
                       <span>Join Inkdstry</span>
<!--                        <span>Family!</span>-->
                    </h2>
                    <ul>
                        <li>Are you ready to take your tattoo studio to the next level? </li>
                        <li>Sign up today and experience the power of Inkdstry</li>
                        <li>Our system is easy to use and fully supported by our team of experts</li>
                    </ul>
                    <p>
                        Let Inkdstry do all the hard work so you can focus
                        on driving your business forward and creating incredible
                        body art
                    </p>
                    <a [routerLink]="['/pricing']">Start Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Keep Your Customers end -->

