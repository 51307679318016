<header id="topss" class="web-header">
    <div class="container">
        <nav>
            <div class="web-logos"></div>
            <div class="web-menu-logo">
                <a [routerLink]="['/home']" class="logo"><img src="./assets/websiteimg/logo.png" class="img-fluid"
                        alt="logo" /></a>
            </div>
            <div class="web-menu-item mobileview-menu-item" [ngClass]="{'is-visible': isSideBarOpen}">
                <div class="handleBtnHideShow btn-close-style" (click)="handleSideBar()"><i
                        class="fa fa-times-circle"></i></div>
                <ul [ngClass]="{'d-flex': !isSideBarOpen}">
                    <li><a class="web-a" [routerLink]="['/home']" routerLinkActive="active">Home </a></li>
                    <li><a class="web-a" [routerLink]="['/pricing']" routerLinkActive="active"> Pricing </a></li>
                    <li><a class="web-a" [routerLink]="['/aboutus']" routerLinkActive="active"> About Us </a></li>
                    <li><a class="web-a" [routerLink]="['/contact']" routerLinkActive="active">Contact</a></li>
                    <li><a class="web-h-contact" [routerLink]="['/login']">LOGIN</a></li>
                </ul>
            </div>
            <div class="handleBtnHideShow btn-close-style" (click)="handleSideBar()"><i class="fa fa-align-justify"
                    aria-hidden="true"></i></div>
            <div class="web-menu-bar">
                <a class="web-a" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                    aria-controls="offcanvasExample">
                    <div class="web-bar">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
            </div>
        </nav>
    </div>
</header>

<!-- offcanvas-area-start -->
<div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
    <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">
            <a href="index.html"><img src="assets/websiteimg/mobile-logo.png" class="img-fluid" alt="" /></a>
        </h5>
        <a class="clloss-x" data-bs-dismiss="offcanvas" aria-label="Close"><i class="fa-solid fa-x"></i></a>
    </div>
    <div class="offcanvas-body">
        <div class="web-mobile-menu-item">
            <ul>
                <li><a class="web-a" [routerLink]="['/home']" routerLinkActive="active">Home </a></li>
                <li><a class="web-a" [routerLink]="['/aboutus']" routerLinkActive="active">About Us </a></li>
                <li><a class="web-a" [routerLink]="['/pricing']" routerLinkActive="active"> Pricing </a></li>
                <li><a class="web-a" [routerLink]="['/contact']" routerLinkActive="active">Contact </a></li>
                <li><a class="web-a" [routerLink]="['/login']">LOGIN</a></li>
            </ul>
        </div>
    </div>
</div>

<router-outlet></router-outlet>

<div class="footer">
    <app-footer></app-footer>
</div>