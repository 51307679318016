import {Component, OnDestroy, OnInit} from '@angular/core';
import { TranslationService } from "../../../core/services/transalation.service";
import { SubscriptionsService } from 'app/core/services/subscriptions.service';
import { commonUtil } from 'app/core/utils/commonUtil';
import { ToastrService } from 'ngx-toastr';
import { UserSessionDto } from 'app/core/models/userSessionDto';
import {Subscription} from "rxjs";

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit, OnDestroy {
  artistID: any = commonUtil.getUserSession().artistID;
  planType: any = commonUtil.getUserSession().planType;
  user: UserSessionDto = commonUtil.getUserSession();
  transactions: any[] = [];
  selectedLanguage: any = 'en';
  translation: any;

  onChangeLanguageSubs = new Subscription();
  onGetLanguageSubs = new Subscription();
  onGetTransactionDetailsSubs = new Subscription();
  onCancelSubscriptionSubs = new Subscription();
  onUpdateCard=new Subscription();

  constructor(private translationService:TranslationService,
    private _service: SubscriptionsService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
      this.selectedLanguage = res;
      this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
        this.translation = data.billing_history;
      });
    });
    this.getTransactionHistory();
  }

  getTransactionHistory() {
    this.onGetTransactionDetailsSubs = this._service.getTransactionDetails(this.artistID).subscribe(async(result) => {
      this.transactions = result.result;
    });
  }

  cancelSubscription() {
    this.onCancelSubscriptionSubs = this._service.cancelSubscription(this.artistID).subscribe(async(result) => {
      this.user.planType = '';
      this.planType = '';
      commonUtil.setLoggedInSession(this.user);
      this.toastr.success("Subscription removed Successfully!");
    });
  }

  ngOnDestroy(): void {
    this.onChangeLanguageSubs.unsubscribe();
    this.onGetLanguageSubs.unsubscribe();
    this.onGetTransactionDetailsSubs.unsubscribe();
    this.onCancelSubscriptionSubs.unsubscribe();
    this.onUpdateCard.unsubscribe();
  }

  routeToStripeBilling(){
    this.onUpdateCard = this._service.updateCard(this.artistID).subscribe(async(result) => {
      window.location.href = result.result.id;
    });
  }
}
