import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {TemplateComponent} from "./template/template.component";
import { AddEditConsentFormComponent } from "./template/consent-form/add-edit-consent-form/add-edit-consent-form.component";
import {TemplateService} from "../../core/services/template.service";
import { AddEditSessionReminderComponent } from "./template/session-reminder/add-edit-session-reminder/add-edit-session-reminder.component";
import {SessionReminderService} from "../../core/services/session-reminder.service";
import {AddEditAftercareComponent} from "./template/aftercare/add-edit-aftercare/add-edit-aftercare.component";
import {AftercareService} from "../../core/services/aftercare.service";
import {ConsentFormService} from "../../core/services/consent-form.service";
import {AddEditConsentFormEmailComponent} from "./template/consent-form/add-edit-consent-form-email/add-edit-consent-form-email.component";
import {ConsentFormEmailService} from "../../core/services/consent-form-email.service";
import {BillingSubscriptionGuard} from "../../core/guards/billing-subscription.guard";

const routes: Routes = [
    {
        path: 'template',
        component: TemplateComponent,
        canActivate: [BillingSubscriptionGuard],
        resolve: {
            data: TemplateService
        },
    },
    {
        path: 'template/consent-form',
        component: AddEditConsentFormComponent,
        canActivate: [BillingSubscriptionGuard],
        resolve: {
            data: ConsentFormService
        },
    },
    {
        path: 'template/consent-form-email',
        component: AddEditConsentFormEmailComponent,
        canActivate: [BillingSubscriptionGuard],
        resolve: {
            data: ConsentFormEmailService
        },
    },
    {
        path: 'template/session-reminder',
        component: AddEditSessionReminderComponent,
        canActivate: [BillingSubscriptionGuard],
        resolve: {
            data: SessionReminderService
        },
    },
    {
        path: 'template/aftercare',
        component: AddEditAftercareComponent,
        canActivate: [BillingSubscriptionGuard],
        resolve: {
            data: AftercareService
        },
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TemplateRoutingModule {
}
