export class SessionReminderDto {
    sessionReminderID: number;
    artistID: number;
    subject: string;
    body: string;

    constructor(sessionReminderDto?) {
        sessionReminderDto = sessionReminderDto || {};
        this.sessionReminderID = sessionReminderDto.sessionReminderID || '';
        this.artistID = sessionReminderDto.artistID || '';
        this.subject = sessionReminderDto.subject || '';
        this.body = sessionReminderDto.body || '';
    }
}
