import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from "rxjs";
import { ResponseDto } from "../models/responseDto";


@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService {
  constructor(private api: ApiService) {}

  public getSessionDetail(artistID: number, planType: string): Observable<ResponseDto> {
    return this.api.post(`/stripe/getSessionDetail`, {artistID, planType});
  }

  public getTransactionDetails(artistID: number): Observable<ResponseDto> {
    return this.api.get(`/transcation/getTranscationHistory/${artistID}`);
  }

  public chargeSessionToken(userId: number, sessionId: string, planType: string): Observable<ResponseDto> {
    return this.api.post(`/stripe/chargeSessionToken`, {userId, sessionId, planType});
  }

  public cancelSubscription(userId: number): Observable<ResponseDto> {
    return this.api.delete(`/stripe/deletePlan/${userId}`);
  }

  public upgradeSubscription(userId: number, planType: string): Observable<ResponseDto> {
    return this.api.put(`/stripe/updatePlan/${userId}/${planType}`, {});
  }

  public updateCard(userId: number): Observable<ResponseDto> {
    return this.api.post(`/stripe/updateCard/${userId}`,null);
  }
}
