import {Injectable} from '@angular/core';
import {commonUtil} from "../utils/commonUtil";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {ResponseDto} from "../models/responseDto";

@Injectable({
    providedIn: 'root'
})
export class AftercareService {

    baseUrl: string = "/template/";
    artistID: any = commonUtil.getUserSession().artistID;

    onAftercareChange: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private api: ApiService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getAftercareDTO()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getAftercareDTO(): Promise<any> {
        return new Promise((resolve, reject) => {

            this.api.getByID(this.baseUrl + 'findAftercareByArtistID/' + this.artistID).subscribe((response: any) => {
                if (response.status === 'SUCCESS') {
                    this.onAftercareChange.next(response.result);
                } else {
                    this.onAftercareChange.next({});
                }
                resolve(response);
            }, reject);
        });
    }

    public saveAftercare(dataRQ: any): Observable<ResponseDto> {
        return this.api.postWithFile<ResponseDto>(
            this.baseUrl + "saveAftercare",
            dataRQ
        );
    }
}
