<section class="web-pricing-hero">
    <div class="container h-100">
        <div class="row h-100 align-items-center">
            <div class="col-md-6 col-lg-8">
                <div class="web-hero-left-text">
                    <h2>Subscription</h2>
                    <h2 class="price-before-line">Monthly Plans</h2>
                    <p>Everything You Need To Succeed</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- hero-area-end -->

<!-- .price-box-area-start -->
<section class="pricing-area">
    <div class="container">
        <div class="price-list-full-item">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="price-box">
                        <div class="price-tag">
                            <h3>Small Studio</h3>
                        </div>
                        <p><span>€99</span>2 users</p>
                        <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                            <ul>
                                <li>Free Setup</li>
                                <li>E-signed Consent Forms</li>
                                <li>Session Reminders</li>
                                <li>Aftercare Automation</li>
                                <li>Client Session History</li>
                                <li>Artist Session History</li>
                                <li>Website Integration</li>
                                <li>Google Calendar Invites</li>
                                <li>Google Calendar Sync</li>
                                <li>Real-time Analytics</li>
                                <li>Technical Support</li>

                            </ul>
                        </div>
                        <div class="package-button text-center">
                            <a [routerLink]="['/login']">Start Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="price-box">
                        <div class="price-tag">
                            <h3>Medium Studio</h3>
                        </div>
                        <p><span>€149</span>5 users</p>
                        <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                            <ul>
                                <li>Free Setup</li>
                                <li>E-signed Consent Forms</li>
                                <li>Email Session Reminders</li>
                                <li>SMS Session Confirmation</li>
                                <li>Aftercare Automation</li>
                                <li>Website Integration</li>
                                <li>Zapier Integration</li>
                                <li>Google Calendar Invites</li>
                                <li>Google Calendar Sync</li>
                                <li>Client Session History</li>
                                <li>Artist Session History</li>
                                <li>Real-time Analytics</li>
                                <li>Technical Support</li>

                            </ul>
                        </div>
                        <div class="package-button text-center">
                            <a [routerLink]="['/login']">Start Now</a>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6 col-md-6 col-lg-4">
                    <div class="price-box">
                        <div class="price-tag">
                            <h3>Large Studio </h3>
                        </div>
                        <p><span>€199</span>7+ users</p>
                        <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                            <ul>
                                <li>Free Setup</li>
                                <li>E-signed Consent Forms</li>
                                <li>Session Reminders</li>
                                <li>Aftercare Automation</li>
                                <li>SMS Session Confirmation</li>
                                <li>Website Integration</li>
                                <li>Zapier Integration</li>
                                <li>Google Calendar Invites</li>
                                <li>Google Calendar Sync</li>
                                <li>Client Session History</li>
                                <li>Artist Session History</li>
                                <li>Real-time Analytics</li>
                                <li>24/7 Technical Support</li>
                                <li>Free Database Import</li>
                                <li>Personal Account Manager</li>
                            </ul>
                        </div>
                        <div class="package-button text-center">
                            <a [routerLink]="['/login']">Start Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- .price-box-area-end -->


<script>
    (function ($) {
        $(window).on("load", function () {
            $(".content").mCustomScrollbar();
        });
    })(jQuery);

    (function ($) {
        $(window).on("load", function () {
            $(".content").mCustomScrollbar();
        });
    })(jQuery);
</script>
