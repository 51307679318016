import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {TemplateService} from "../../../../core/services/template.service";
import {UserDTO} from "../../../../core/models/userDto";
import {Subscription} from "rxjs";
import {ToastrService} from "ngx-toastr";

@Component({
    selector: 'app-template-status-update',
    templateUrl: './template-status-update.component.html',
    styleUrls: ['./template-status-update.component.scss']
})
export class TemplateStatusUpdateComponent implements OnInit, OnDestroy {

    content: any;
    toastMsg: string = 'Test';
    onTemplateStatusUpdateSubs = new Subscription();

    constructor(public dialogRef: MatDialogRef<TemplateStatusUpdateComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private templateService: TemplateService,
                private toasterService: ToastrService) {
    }

    ngOnInit(): void {
        switch (this.data.templateType) {
            case 'CONSENT_FORM':
                if (this.data.status === 'ACT') {
                    this.content = 'Active Consent Form';
                    this.toastMsg = 'Consent form activated successfully';
                } else {
                    this.content = 'Deactivate Consent Form';
                    this.toastMsg = 'Consent form deactivated successfully';

                }
                break;

            case 'SESSION_REMINDER':
                if (this.data.status === 'ACT') {
                    this.content = 'Active Session Reminder';
                    this.toastMsg = 'Session reminder activated successfully';
                } else {
                    this.content = 'Deactivate Session Reminder';
                    this.toastMsg = 'Session reminder deactivated successfully';
                }
                break;

            case 'AFTERCARE':
                if (this.data.status === 'ACT') {
                    this.content = 'Active Aftercare';
                    this.toastMsg = 'Aftercare activated successfully';
                } else {
                    this.content = 'Deactivate Aftercare';
                    this.toastMsg = 'Aftercare deactivated successfully';
                }
                break;
        }

        this.onTemplateStatusUpdateSubs = this.templateService.onTemplateStatusUpdate
            .subscribe(response => {
                if (response) {
                    if (response.status == "SUCCESS") {
                        this.toasterService.success(this.toastMsg);
                        this.templateService.getUserDTO();
                        this.onCancelClick();
                    } else if (response.status == "FAILED") {
                        response.appsErrorMessages.forEach((s) => {
                            this.toasterService.error(s.errorMessage);
                        });
                    } else {
                        this.toasterService.error("Something went wrong, Please try again");
                    }
                } else {
                    this.toasterService.error("Something went wrong, Please try again");
                }
            });
    }

    onConfirm($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        this.templateService.updateTemplateStatus(this.data);
    }

    onCancelClick() {
        this.dialogRef.close(false);
    }

    ngOnDestroy(): void {
        this.onTemplateStatusUpdateSubs.unsubscribe();
    }
}
