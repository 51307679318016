import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {SessionReminderDto} from "../../../../../core/models/session-reminder-dto";
import {TranslationService} from "../../../../../core/services/transalation.service";
import {SessionReminderService} from "../../../../../core/services/session-reminder.service";
import {ToastrService} from "ngx-toastr";
import {commonUtil} from "../../../../../core/utils/commonUtil";
import {AftercareDto} from "../../../../../core/models/aftercare-dto";
import {AftercareService} from "../../../../../core/services/aftercare.service";

@Component({
    selector: 'app-add-edit-aftercare',
    templateUrl: './add-edit-aftercare.component.html',
    styleUrls: ['./add-edit-aftercare.component.scss']
})
export class AddEditAftercareComponent implements OnInit, OnDestroy {

    addEditAftercare: FormGroup;
    translation: any;
    selectedLanguage: any = "en";

    onAftercareChangeSubs = new Subscription();
    onChangeLanguageSubs = new Subscription();
    onGetLanguageSubs = new Subscription();
    onSaveAftercareSubs = new Subscription();

    aftercareDto: AftercareDto = new AftercareDto();

    artistID: any;

    constructor(private translationService: TranslationService,
                private aftercareService: AftercareService,
                private formBuilder: FormBuilder,
                private toasterService: ToastrService,) {
    }

    ngOnInit(): void {
        this.artistID = commonUtil.getUserSession().artistID;

        this.onAftercareChangeSubs = this.aftercareService.onAftercareChange
            .subscribe(aftercare => {
                this.aftercareDto = new AftercareDto(aftercare);
            });

        this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
            this.selectedLanguage = res;
            this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
                this.translation = data.add_edit_consent_form;
            });
        });

        this.initForm();
    }

    initForm() {
        this.addEditAftercare = this.formBuilder.group({
            subject: [this.aftercareDto.subject, Validators.compose([
                Validators.required
            ])],
            body: [this.aftercareDto.body, Validators.compose([
                Validators.required
            ])]
        });
    }

    ngOnDestroy(): void {
        this.onAftercareChangeSubs.unsubscribe();
        this.onChangeLanguageSubs.unsubscribe();
        this.onGetLanguageSubs.unsubscribe();
        this.onSaveAftercareSubs.unsubscribe();
    }

    isControlHasError(controlName: string, validationType: string): boolean {
        const control = this.addEditAftercare.controls[controlName];
        if (!control) {
            return false;
        }

        const result = control.hasError(validationType) && (control.dirty || control.touched);
        return result;
    }

    isFormValid() {
        return this.addEditAftercare.valid;
    }

    onSaveData($event: MouseEvent) {
        if ($event) {
            $event.stopPropagation();
            $event.preventDefault();
        }

        let formData = Object.assign({}, this.addEditAftercare.getRawValue());
        formData.artistID = this.artistID;

        this.onSaveAftercareSubs = this.aftercareService.saveAftercare(formData).subscribe((result) => {
            if (result) {
                if (result.status == "SUCCESS") {
                    this.toasterService.success("Aftercare Saved successfully");
                } else if (result.status == "FAILED") {
                    result.appsErrorMessages.forEach((s) => {
                        this.toasterService.error(s.errorMessage);
                    });
                } else {
                    this.toasterService.error("Something went wrong, Please try again");
                }
            } else {
                this.toasterService.error("Something went wrong, Please try again");
            }
        });
    }

}
