<div class="container-fluid">
    <div class="row view-pdf-header">
        <div class="col-md-12">
            <div class="row">
                <div class="col-12 d-flex align-items-start justify-content-between">
                    <h2 style="margin-top: 12px">{{title}}</h2>

                    <a class="close-btn" (click)="onCancelClick()">
                        <img class="close-img" src="assets/img/close.png" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-top: 20px">
        <!-- Use ngx-extended-pdf-viewer to show consent form preview      -->
        <ng-container *ngIf="isConsentForm === 'Y'">
            <ngx-extended-pdf-viewer
                    [src]="pdfSrc"
                    [useBrowserLocale]="true" height="80vh">
            </ngx-extended-pdf-viewer>
        </ng-container>

        <!-- Use pdf-viewer to show customer pdf preview      -->
        <ng-container *ngIf="isConsentForm !== 'Y'">
            <pdf-viewer [src]="pdfSrc"
                        [render-text]="true"
                        [original-size]="true"
                        style="height: 600px"
            >
            </pdf-viewer>
        </ng-container>
    </div>
</div>