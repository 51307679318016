<div class="wrapper">
    <div class="sidebar" #elementRef [ngClass]="{'sidebarOpen': !isSideBarOpen}" data-image="">
        <span class="menuHideShowBtn" (click)="handleHideShowSideBar()">
            <img src="../../../assets/img/menu-icon.png" width="30" />
        </span>
        <app-sidenav></app-sidenav>
        <div class="sidebar-background"></div>
    </div>

    <div class="main-panel" id="main-panel-cs" [ngClass]="{'main-wrapper-without-sidebar': !isSideBarOpen}">
        <app-toolbar></app-toolbar>
        <router-outlet></router-outlet>
        <div *ngIf="isMap('maps')">
            <app-footer></app-footer>
        </div>
    </div>
</div>