<div class="row aftercare-head">
    <div class="col-md-12 bg-clients">
        <div class="table-box">
            <div class="row">
                <div class="col-md-12 clients-list">
                    <div class="row">
                        <div class="col-md-12">
                            <p class="table-heading">Add/Edit Consent Form Email</p>
                        </div>
                    </div>
                </div>

                <div class="row m-l-r-15">
                    <form [formGroup]="addEditConsentFormEmail" autocomplete="off" class="kt-form" id="consent-form">
                        <div class="row">
                            <div class="col-md-12" style="margin-top: 20px">
                                <input type="text"
                                       formControlName="subject"
                                       maxlength="128"
                                       class="form-control aftercare-form-input"
                                       placeholder="{{translation?.email_subject}}"
                                       autocomplete=" new-password">
                                <div *ngIf="isControlHasError('subject','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('subject','required')">
                                        Email subject is required
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12" style="margin-top: 20px">
                                <textarea type="text"
                                          formControlName="body"
                                          class="form-control aftercare-form-input"
                                          rows="8"
                                          maxlength="1024"
                                          placeholder="{{translation?.email_body}}"
                                          autocomplete=" new-password">
                                </textarea>
                                <div *ngIf="isControlHasError('body','required')">
                                    <span class="text-danger" *ngIf="isControlHasError('body','required')">
                                        Email body is required
                                    </span>
                                </div>
                            </div>
                        </div>
                    </form>

                </div>

                <div class="row m-l-r-15" style="margin-top: 20px">
                    <div class="col-md-12" style="text-align: end">
                        <button class="btn btn-default aftercare-save-btn"
                                style="margin-right: 10px"
                                [disabled]="!isFormValid()"
                                (click)="onSaveData($event)"
                        >
                            {{translation?.btn_save}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
