import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { ApiService } from "./api.service";

import { ResponseDto } from "../models/responseDto";
import {commonUtil} from "../utils/commonUtil";

@Injectable({
  providedIn: "root",
})
export class ArtistService {

  baseUrl: string = "/users/";
  artistID: any = commonUtil.getUserSession().artistID;

  constructor(private api: ApiService) {}

  public CreateOrUpdate(entity: any): Observable<ResponseDto> {
    if (isNaN(entity.artistID) || entity.artistID == 0) {
      entity.role = 'USER';
      entity.currentUserID = this.artistID;
      return this.api.postWithFile<ResponseDto>(
          this.baseUrl + "sign-up",
          entity
      );
    } else {
      return this.api.putWithFile<ResponseDto>(
        this.baseUrl + "updateUser/" + entity.artistID,
        entity
      );
    }
  }

  public SignIn(entity: any): Observable<ResponseDto> {
    return this.api.post<ResponseDto>(this.baseUrl + "sign-in", entity);
  }

  public getAll(): Observable<ResponseDto> {
    return this.api.get(this.baseUrl + `allUsers`);
  }

  public getById(id: any): Observable<any> {
    return this.api.get(this.baseUrl + `getById?Id=${id}`);
  }
}
