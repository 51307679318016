import {Component, OnDestroy, OnInit} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountService } from "app/core/services/account.service";
import { TranslationService } from "../../core/services/transalation.service";
import { RegisterDto } from "../../core/models/registerDto";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { commonUtil } from "app/core/utils/commonUtil";
import {Subscription} from "rxjs";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit, OnDestroy {
  registerForm: FormGroup;
  submitted: boolean = false;
  selectedLanguage: any = "en";
  translation: any;
  model: RegisterDto = new RegisterDto();

  onChangeLanguageSubs = new Subscription();
  onGetLanguageSubs = new Subscription();
  onRegisterSubs = new Subscription();

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private translationService: TranslationService,
    private _service: AccountService,
    private toastr: ToastrService
  ) {
    this.registerForm = this.fb.group({
      username: [
        "",
        [Validators.required],
        // [uniqueUserNameValidator(_service)],
      ],
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      telephone: ["", Validators.required],
      email: [
        "",
        [Validators.required, Validators.email],
        // [uniqueEmailValidator(_service)],
      ],
      password: ["", Validators.required],
      taxNumber: [""],
    });

    this.onChangeLanguageSubs = this.translationService.language.subscribe((res: any) => {
      this.selectedLanguage = res;
      this.onGetLanguageSubs = this.translationService.get().subscribe((data: any) => {
        this.translation = data.registration;
      });
    });
  }

  ngOnInit(): void {
    if (commonUtil.isLoggedIn()) {
      this.route.navigate(["dashboard"]);
    }
  }
  registerUser(registerForm: FormGroup) {
    this.submitted = true;
    if (!registerForm.invalid) {
      this.submitted = false;
      this.onRegisterSubs = this._service.Register(this.model).subscribe((result) => {
        if (result) {
          if (result.status == "SUCCESS") {
            this.toastr.success("Register Successfully!");
            this.route.navigate(["login"]);
          } else if (result.status == "FAILED") {
            result.appsErrorMessages.forEach((s) => {
              this.toastr.error(s.errorMessage);
            });
          } else {
            this.toastr.error("Someting went wrong during register user");
          }
        } else {
          this.toastr.error("Someting went wrong during register user");
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.onChangeLanguageSubs.unsubscribe();
    this.onGetLanguageSubs.unsubscribe();
    this.onRegisterSubs.unsubscribe();
  }
}
