
<!-- .price-box-area-start -->
<section class="pricing-area">
  <div class="container">
      <div class="price-list-full-item">
          <div class="row">
              <div class="col-sm-6 col-md-6 col-lg-4">
                  <div class="price-box">
                      <div class="price-tag">
                          <h3>Small Studio</h3>
                      </div>
                      <p><span>€99</span>2 users</p>
                      <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                          <ul>
                              <li>Free Setup</li>
                              <li>E-signed Consent Forms</li>
                              <li>Session Reminders</li>
                              <li>Aftercare Automation</li>
                              <li>Client Session History</li>
                              <li>Artist Session History</li>
                              <li>Real-time Analytics</li>
                              <li>Technical Support</li>

                          </ul>
                      </div>
                      <div class="package-button text-center">
                          <a href="javascript:;" *ngIf="!planType" (click)="getSesionDetails('SMALL')">Start Now</a>
                          <a href="javascript:;" *ngIf="planType == 'SMALL' else smallElse" >Current Plan</a>
                          <ng-template #smallElse>
                            <a href="javascript:;" *ngIf="planType" (click)="upgradeSubscription('SMALL')">Upgrade Now</a>
                          </ng-template>
                      </div>
                  </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4">
                  <div class="price-box">
                      <div class="price-tag">
                          <h3>Medium Studio</h3>
                      </div>
                      <p><span>€149</span>6 users</p>
                      <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                          <ul>
                              <li>Free Setup</li>
                              <li>E-signed Consent Forms</li>
                              <li>Session Reminders</li>
                              <li>Aftercare Automation</li>
                              <li>Client Session History</li>
                              <li>Artist Session History</li>
                              <li>Real-time Analytics</li>
                              <li>Technical Support</li>
                              <li>Free Database Import</li>

                          </ul>
                      </div>
                      <div class="package-button text-center">
                          <a href="javascript:;" *ngIf="!planType" (click)="getSesionDetails('MEDIUM')">Start Now</a>
                          <a href="javascript:;" *ngIf="planType == 'MEDIUM' else mediumElse" >Current Plan</a>
                          <ng-template #mediumElse>
                            <a href="javascript:;" *ngIf="planType" (click)="upgradeSubscription('MEDIUM')">Upgrade Now</a>
                          </ng-template>
                      </div>
                  </div>
              </div>
              <div class="col-sm-6 col-md-6 col-lg-4">
                  <div class="price-box">
                      <div class="price-tag">
                          <h3>Large Studio </h3>
                      </div>
                      <p><span>€229</span>7+ users</p>
                      <div class="price-list longEnough mCustomScrollbar" data-mcs-theme="dark">
                          <ul>
                              <li>Free Setup</li>
                              <li>E-signed Consent Forms</li>
                              <li>Session Reminders</li>
                              <li>Aftercare Automation</li>
                              <li>Client Session History</li>
                              <li>Artist Session History</li>
                              <li>Real-time Analytics</li>
                              <li>24/7 Technical Support</li>
                              <li>Free Database Import</li>
                              <li>Personal Account Manager</li>
                          </ul>
                      </div>
                      <div class="package-button text-center">
                          <a href="javascript:;" *ngIf="!planType" (click)="getSesionDetails('LARGE')">Start Now</a>
                          <a href="javascript:;" *ngIf="planType == 'LARGE' else largeElse" >Current Plan</a>
                          <ng-template #largeElse>
                            <a href="javascript:;" *ngIf="planType" (click)="upgradeSubscription('LARGE')">Upgrade Now</a>
                          </ng-template>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section>
<!-- .price-box-area-end -->
