import {Injectable} from '@angular/core';
import {commonUtil} from "../utils/commonUtil";
import {BehaviorSubject, Observable} from "rxjs";
import {ApiService} from "./api.service";
import {ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {ResponseDto} from "../models/responseDto";

@Injectable({
    providedIn: 'root'
})
export class SessionReminderService {

    baseUrl: string = "/template/";
    artistID: any = commonUtil.getUserSession().artistID;

    onSessionReminderChange: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(private api: ApiService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getSessionReminderDTO()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }

    getSessionReminderDTO(): Promise<any> {
        return new Promise((resolve, reject) => {

            this.api.getByID(this.baseUrl + 'findSessionReminderByArtistID/' + this.artistID).subscribe((response: any) => {
                if (response.status === 'SUCCESS') {
                    this.onSessionReminderChange.next(response.result);
                } else {
                    this.onSessionReminderChange.next({});
                }
                resolve(response);
            }, reject);
        });
    }

    public saveSessionReminder(dataRQ: any): Observable<ResponseDto> {
        return this.api.postWithFile<ResponseDto>(
            this.baseUrl + "saveSessionReminder",
            dataRQ
        );
    }
}
